import { Button, Container, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { HEADER, SPACING } from 'src/config-global';
import { useStores } from 'src/models/root-store/root-store-context';

import Image from '../../components/image';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { toJS } from 'mobx';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */

export const Login = observer(() => {

  const theme = useTheme();
  const rootStore = useStores();
  const { userStore } = rootStore;
  const navigate = useNavigate();
  useEffect(() => {
    if (userStore.user.userId !== '') {
      navigate('/report');
    }
  }, [userStore.user])

  return (
    <>
      {
        (userStore.user.userId === '') &&
        <Container sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
          <Box
            component="main"
            sx={{
              maxWidth: theme.breakpoints.values.lg,
            }}>
            {/* <Header isOffset={isOffset} /> */}
            <Stack
              sx={{
                py: 0,
                pt: `${HEADER.H_MOBILE + SPACING}px`,
                pb: `${HEADER.H_MOBILE + SPACING}px`,
                m: 'auto',
                maxWidth: theme.breakpoints.only('lg'),
                minHeight: '100vh',
                textAlign: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Image alt="GenTok" src="/assets/images/logo-h.png" maxWidth={{ xs: '70vw', lg: '50vw', xl: '30vw' }} sx={{ margin: '10vh auto' }} disabledEffect />

              <Stack
                spacing={2}
              >
                <Button color={'info'} size={'large'} variant={'contained'} component={Link} to="/report" >처음 사용자</Button>
                <Button color={'secondary'} size={'large'} variant={'contained'} component={Link} to="/agree?exist=true" >기존 사용자</Button>
              </Stack>
            </Stack>
          </Box>
        </Container>
      }
    </>
  );
});

export default Login;