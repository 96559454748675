import { Button, Checkbox, Fab, FormGroup, Stack, Typography, useTheme, Dialog, Slide, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { HEADER, SPACING } from 'src/config-global';
import { useStores } from "../../../models/root-store/root-store-context";
import Image from '../../../components/image';
import React, { useEffect, useState } from 'react';

import CheckedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckIcon from '@mui/icons-material/TaskAltOutlined';
import { Link, useLocation } from 'react-router-dom';
import { CheckPhone, Collect, Privacy, Terms } from 'src/routes/elements';
import { TransitionProps } from '@mui/material/transitions';
import { useSearchParams } from "react-router-dom";
import { load, loadString } from 'src/utils/storage';
import { uuidForGentok } from 'src/utils/uuidv4';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { toJS } from 'mobx';
import { blue } from '@mui/material/colors';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
export const AgreeInfo = observer(() => {

  const theme = useTheme();
  const rootStore = useStores();
  const { userStore, loadingStore } = rootStore;

  const [check1, setCheck1] = useState<boolean>(false);
  const [check2, setCheck2] = useState<boolean>(false);

  const [openCheckPhone, setOpenCheckPhone] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { REACT_APP_API_URL } = process.env;

  const existingUser = async () => {
    const url = REACT_APP_API_URL + 'selectAuth/' + userStore.os;
    sendReactNativeMessage({
      type: 'verify',
      payload: {
        url: url
      }
    });
  }

  const [dialogType, setDialogType] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);

  const onDialog = (type: string = '') => {
    setDialogType(type);
    setOpenDialog(true);
  }

  const closeDialog = () => {
    setOpenDialog(false);
  }

  const dialogContent = () => {
    switch (dialogType) {
      case 'terms':
        return (<Terms handleClose={closeDialog} handleAgree={() => { closeDialog(); setCheck2(true) }} />)
        break;
      case 'privacy':
        return (<Privacy handleClose={closeDialog} />)
        break;
      case 'collect':
        return (<Collect handleClose={closeDialog} handleAgree={() => { closeDialog(); setCheck1(true) }} />)
        break;
    }
  }

  const btnStyle = {
    width: '100%',
    background: blue[600],
    '&:hover': {
      background: blue[600]
    }
  }

  return (
    <>
      <Stack
        sx={{
          py: 0,
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          m: 'auto',
          maxWidth: theme.breakpoints.only('lg'),
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'space-between',
        }}
      >

        <Box sx={{ margin: '10vh auto' }}>

          <Image alt="GenTok" src="/assets/images/agree-illust.png" sx={{ pl: 8, pr: 8, mb: { xs: 4, md: 2, lg: 4 } }} />

          <Typography variant={'h6'}>다음 약관에 동의해주세요.</Typography>

          <Stack sx={{ mt: 2 }}>
            <FormGroup sx={{ margin: 'auto' }}>

              <Stack direction={'row'} alignContent={'center'} alignItems={'center'} sx={{ mb: 1 }}>
                <Checkbox icon={<CheckedIcon />} checkedIcon={<CheckIcon />} checked={check1 && check2} onClick={(e: any) => {
                  if (e.nativeEvent.target.checked !== undefined) {
                    setCheck1(e.nativeEvent.target.checked)
                    setCheck2(e.nativeEvent.target.checked)
                  }
                }} />
                <Typography variant={'body2'} onClick={() => {
                  setCheck1(!(check1 && check2))
                  setCheck2(!(check1 && check2))
                }}>GenTok 이용약관 전체 동의</Typography>
              </Stack>

              <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
                <Checkbox icon={<CheckedIcon />} checkedIcon={<CheckIcon />} checked={check1} onClick={(e: any) => {
                  if (e.nativeEvent.target.checked !== undefined) {
                    setCheck1(e.nativeEvent.target.checked)
                  }
                }} />
                <Typography variant={'body2'} onClick={() => onDialog('collect')}>개인정보 수집 및 이용 목적 (필수)</Typography>
              </Stack>
              <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
                <Checkbox icon={<CheckedIcon />} checkedIcon={<CheckIcon />} checked={check2} onClick={(e: any) => {
                  if (e.nativeEvent.target.checked !== undefined) {
                    setCheck2(e.nativeEvent.target.checked)
                  }
                }} />
                <Typography variant={'body2'} onClick={() => onDialog('terms')}>서비스 이용약관 (필수)</Typography>
              </Stack>

            </FormGroup>
          </Stack>
        </Box>

        <Box>
          <Button variant={'text'} sx={{ color: theme.palette.text.secondary, fontWeight: '200' }} onClick={() => onDialog('privacy')} >개인정보 처리방침</Button>
          {
            params.get('exist') !== null ?
              <Fab disabled={!check1 || !check2} aria-label='NEXT' variant={'extended'} sx={btnStyle}
                onClick={() => existingUser()}>NEXT</Fab>
              :
              <Fab disabled={!check1 || !check2} aria-label='NEXT' variant={'extended'} sx={btnStyle}
                onClick={() => setOpenCheckPhone(true)}>NEXT</Fab>
          }
        </Box>

      </Stack>


      <Dialog
        fullWidth
        keepMounted
        maxWidth={'lg'}
        open={openDialog}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            p: 0,
            m: 0,
            maxHeight: '100%',
            minHeight: '100%',
            borderRadius: '0 !important',
            '@media (max-width: 600px)': {
              margin: 0
            }
          }
        }}
        onClose={() => {
          setOpenDialog(false);
          sendReactNativeMessage({
            type: 'topBackgroundChange',
            payload: {
              color: '#f5f5f5'
            }
          });
        }}
        sx={{
          margin: '0 !important', zIndex: 9999999, padding: 0, borderRadius: 0
        }}
      >
        {dialogContent()}
      </Dialog>

      <Dialog
        fullWidth
        keepMounted
        maxWidth={'lg'}
        open={openCheckPhone}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            p: 0,
            m: 0,
            maxHeight: '100%',
            minHeight: '100%',
            borderRadius: '0 !important',
            '@media (max-width: 600px)': {
              margin: 0
            }
          }
        }}
        onClose={() => {
          setOpenCheckPhone(false);
        }}
        sx={{ margin: '0 !important', zIndex: 9999999 }}
      >
        <CheckPhone handleClose={() => setOpenCheckPhone(false)} />
      </Dialog>
    </>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default AgreeInfo;