import { Instance, ISimpleType, SnapshotOut, types } from "mobx-state-tree"
import { format } from 'date-fns';
import { Trait } from "../trait/Trait";
import { CardioAndCerebroEnv } from "../cardio-and-cerebro-env/CardioAndCerebroEnv";
import { RsValue } from "../rs-value/RsValue";
import { ReportOverview } from '../report-overview/ReportOverview';
import { RsDesc } from "../rs-desc/RsDesc";

/**
 * # Report
 *
 * Report을 설명하세요.
 */
export const Report = types
  .model("Report")
  // --------------------------------------------------------------------------
  .props({
    age: types.maybeNull(types.number),
    birth: types.maybeNull(types.number),

    cancers: types.optional(types.array(Trait), []),
    cardioEnv: types.maybeNull(CardioAndCerebroEnv),
    cardios: types.optional(types.array(Trait), []),
    // completeDate: types.maybeNull(ReportOverview),
    collectedDate: types.maybeNull(types.number),
    formatVer: types.optional(types.string, ''),
    gender: types.optional(types.string, ''),
    hasPathogenic: types.optional(types.boolean, false),
    hospitalCode: types.optional(types.string, ''),
    hospitalName: types.optional(types.string, ''),

    kitId: types.optional(types.string, ''),
    orderId: types.optional(types.string, ''),
    overviewLong: types.maybeNull(ReportOverview),
    overviewShort: types.maybeNull(ReportOverview),
    pdfUrl: types.optional(types.string, ''),


    productCode: types.optional(types.string, ''),
    productName: types.optional(types.string, ''),
    productSubName: types.optional(types.string, ''),
    receiptedDate: types.maybeNull(types.number),
    reportVer: types.maybeNull(types.number),
    reportedDate: types.maybeNull(types.number),
    // rsValue: types.maybeNull(types.array(RsValue)),
    rsDesc: types.maybeNull(types.array(RsDesc)),

    tab1: types.optional(types.string, ''),
    tab2: types.optional(types.string, ''),
    // collectDate: types.optional(types.string, ''),
    // chronics: types.maybeNull(types.array(Trait)),

  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model report --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IReport;
      (Object.keys(newProps) as TReportKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TReport = Instance<typeof Report>;
type TReportSnapshot = SnapshotOut<typeof Report>

export interface IReport extends TReport { }
export type TReportKeys = keyof TReportSnapshot & string;
export interface IReportSnapshot extends TReportSnapshot { }
export const createReport = () =>
  types.optional(Report, {
  } as TReport);
function moment(number: ISimpleType<number>) {
  throw new Error("Function not implemented.");
}
