import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # MetaTrait
 *
 * MetaTrait을 설명하세요.
 */
export const MetaTrait = types
  .model("MetaTrait")
  // --------------------------------------------------------------------------
  .props({
    id: types.number,
    code: types.string,
    category: types.string,
    descShort: types.string,
    english: types.string,
    korean: types.string,
    iconApp: types.string,
    pathogenicApp: types.string,
    traitImageUrl: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model metaTrait --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMetaTrait;
      (Object.keys(newProps) as TMetaTraitKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMetaTrait = Instance<typeof MetaTrait>;
type TMetaTraitSnapshot = SnapshotOut<typeof MetaTrait>

export interface IMetaTrait extends TMetaTrait { }
export type TMetaTraitKeys = keyof TMetaTraitSnapshot & string;
export interface IMetaTraitSnapshot extends TMetaTraitSnapshot { }
export const createMetaTrait = () =>
  types.optional(MetaTrait, {
  } as TMetaTrait);
