// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from './auth/JwtContext';
import { useEffect, useState } from 'react';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import { GlobalDebug } from './utils/globalDebug';
// import { getReactNativeMessage } from './utils/reactNativeMessage';
import { CommonProvider } from './components/CommonContext';
import { sendReactNativeMessage } from './utils/sendReactNativeMessage';
import LoadingAPI from './components/LoadingAPI';
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {

  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {
    (async () => {
      setupRootStore().then((store) => {
        setRootStore(store);
        sendReactNativeMessage({
          type: 'initUser',
          payload: null
        });
      });
    })();
    if (window.localStorage.getItem('useDebug') !== 'true') {
      (process.env.NODE_ENV === 'production') && GlobalDebug(false, false);
    }
  }, []);
  if (!rootStore) {
    return null;
  } else {
    return (
      <RootStoreProvider value={rootStore}>
        <AuthProvider>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <CommonProvider>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <StyledChart />
                            <Router />
                            <LoadingAPI />
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </CommonProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </AuthProvider>
      </RootStoreProvider>
    );
  }
}