export const PLACE_HOLDER = {
  common: {
    select: '선택',
  },
};

export const ALERT_MSG = {
  common: {
    insert: '등록하시겠습니까?',
    update: '수정하시겠습니까?',
    delete: '삭제하시겠습니까?',
    save: '저장하시겠습니까?',
    loginError: '로그인 에러',
    noFile: '파일을 첨부하세요',
    uploadInfo: '장비 업로드가 시작됩니다.',
    otpExpired: 'OTP시간이 만료되었습니다.',
  },
  systemManagement: {
    userMgmt: {
      reject: '승인 거절하시겠습니까?',
      approval: '승인 완료하시겠습니까?',
      inactiveClear: '휴면 해제하시겠습니까?',
      availableLoginId: '사용가능한 아이디입니다',
      unavailableLoginId: '사용할 수 없는 아이디입니다',
      emptyLoginId: '로그인 ID를 입력하세요',
      duplicateCheckLoginId: '로그인 ID 중복확인을 하세요',
      pwdNotCorrect: '비밀번호와 비밀번호 확인이 다릅니다',
    },
    usrGrpMgmt: {
      availableLoginId: '사용가능한 이름입니다',
      unavailableLoginId: '사용할 수 없는 이름입니다',
      duplicateCheckLoginId: '그룹 이름을 중복확인 하세요',
      emptyLoginId: '그룹 이름을 입력하세요',
    },
  },
  management: {
    hostInfoMgmt: {
      updates: '대량으로 데이터가 수정됩니다. 계속 진행하시겠습니까?',
      oprtnTm: '설정된 플랫폼이 새로운 운용팀에 할당이 됩니다. 계속 진행하시겠습니까?',
      availableTmNm: '사용가능한 운용팀명 입니다',
      unavailableTmNm: '사용할 수 없는 운용팀명 입니다',
      emptyTmNm: '운용팀명을 입력하세요',
    },
    certificateMgmt: {
      upload: '인증서 업로드',
      uploadInfo: '인증서 업로드가 시작됩니다.',
    },
  },
};

export const SNACKBAR_MSG = {
  common: {
    insert: '등록되었습니다.',
    update: '수정되었습니다.',
    delete: '삭제되었습니다.',
    save: '저장되었습니다.',
    error: '에러가 발생하였습니다.',
  },
  alarmManagement: {
    test: '테스트를 완료하였습니다.',
    renewal: '갱신되었습니다.',
    reflection: '반영되었습니다.',
  },
  circuitInfo: {
    sync: '데이터가 갱신되었습니다.',
    syncAlready: '데이터가 이미 최신 상태입니다.',
    syncFailed: '데이터 갱신에 실패하였습니다.',
  },
  configurationManagement: {
    channelMgmt: {
      copy: '복사되었습니다.',
    },
  },
  systemManagement: {
    userMgmt: {
      reject: '승인 거절되었습니다.',
      approval: '승인 완료되었습니다.',
      inactiveClear: '휴면 해제되었습니다.',
    },
  },
};

export const MENU_PAGE_TITLE = {
  dashboard: {
    title: '대시보드',
    dashboard: '대시보드',
    performance: '성능현황',
  },
  statistics: {
    title: '알람 관리',
    report: '알람 레포트',
    history: '알람 이력 조회',
  },
  cdr: {
    title: 'CDR',
    search: 'CDR 검색',
  },
  standardInfomation: {
    title: '기준 정보',
    tdm: 'TDM 선번장 관리',
    stp: 'STP 선번장 관리',
    sigtran: 'SIGTRAN 선번장 관리',
    dra: 'DRA 선번장 관리',
    c3: 'C3 알람 관리',
    stpAlarm: 'STP 알람 관리',
  },
  systemManagement: {
    title: '시스템 관리',
    userMgmt: '사용자 관리',
    groupMgmt: '그룹 관리',
    groupMenuMgmt: '그룹 메뉴 관리',
    apiHistory: 'API 이력 조회',
  },
};

export const DIALOG_TITLE = {
  login: {
    otp: {
      title: 'OTP 인증',
      modal: 'OTP 번호입력',
    },
  },
  systemManagement: {
    userMgmt: {
      detail: '사용자 상세정보',
      insert: '사용자 등록',
      update: '사용자 수정',
    },
    groupMgmt: {
      detail: '그룹 상세보기',
      insert: '그룹 등록',
      update: '그룹 수정',
    },
  },
  management: {
    monitoring: {
      detail: '감시시설 상세',
      insert: '경보 제외 등록',
    },
    hostInfoMgmt: {
      detail: '호스트 상세',
      insert: '신규 호스트 등록',
      update: '호스트 수정',
      updateBatch: '호스트 일괄 수정',
      platformMgmt: '플랫폼 조직 관리',
      operationTeam: '운용팀 등록',
    },
    certificateMgmt: {
      detail: '인증서 상세',
      insert: '인증서 등록',
      update: '인증서 수정',
      updates: '인증서 일괄 등록/수정',
    },
  },
};

export const FORMSET_TITLE = {
  systemManagement: {
    userMgmt: {
      basicInfo: '기본정보',
    },
    groupMgmt: {
      basicInfo: '기본정보',
    },
    apiHistorySearch: {
      basicInfo: '기본정보',
    },
    ppCompanyMgmt: {
      basicInfo: '기본정보',
    },
  },
  alarmManagement: {
    pairAlarmMgmt: {
      basicInfo: '기본정보',
      eventType: '알람 유형',
    },
    ruleset: {
      basicInfo: '기본정보',
      textMappingList: 'TEXT 매핑 리스트',
      ruleSetMappingInfo: 'RuleSet 매핑 정보',
      MappingCondition: '매핑 조건',
    },
    snmpRuleset: {
      basicInfo: '기본정보',
      settingInfo: '설정 정보',
    },
    stbSimulator: {
      basicInfo: '기본정보',
    },
  },
};

export const FORMSET_ITEM_LABEL = {
  common: {
    reg: '등록',
    upd: '수정',
    attachment: '첨부 파일',
  },
  systemManagement: {
    userMgmt: {
      userSid: 'ID',
      loginId: '로그인 ID',
      userNm: '이름',
      usrgrpSidList: '그룹',
      roleList: '권한 목록',
      acntSttsCd: '계정상태',
      pwd: '비밀번호',
      pwdCorrect: '비밀번호 확인',
      regSdt: '등록일시(시작)',
      regEdt: '등록일시(끝)',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
      sessn: '세션유지여부',
      lastLoginDt: '최근 로그인',
    },
    myPage: {
      basic: '마이페이지',
      myInfo: '내 정보',
    },
    groupMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
      dscrpt: '설명',
      hasRole: '보유권한',
      regUser: '등록자',
      updUser: '수정자',
      regDt: '등록일시',
      updDt: '수정일시',
    },
    groupMenuMgmt: {
      usrgrpSid: '그룹 ID',
      usrgrpNm: '그룹명',
    },
    apiHistorySearch: {
      recvDt: '요청된 일시',
      transacId: '트랜젝션ID',
      hostNm: '호스트명',
      clientAdress: '클라이언트 주소',
      method: '메소드',
      uri: 'URI',
      requsetBody: 'Request Body',
      result: '결과',
      httpStatus: 'HTTP STATUS',
      errCd: '에러코드',
      errMsg: '에러메세지',
      responseContentLength: 'Response 컨텐츠 길이',
      takeTime: '소요시간',
    },
  },
  statisticsReporting: {
    alarmHistory: {
      eventId: '알람ID',
      intrlkSysSid: 'NMS ID',
      instanceType: '인스턴스 유형',
      instanceName: '인스턴스 이름',
      targetInstanceName: '장비 이름',
      instanceId: '인스턴스 ID',
      portId: '포트 ID',
      portName: '포트 이름',
      instType2: '인스턴스 유형Z',
      instNm2: '인스턴스 이름Z',
      instId2: '인스턴스 IDZ',
      portId2: '포트 IDZ',
      portNm2: '포트 이름 IDZ',
      eventTrgtType: '알람 타겟 유형',
      eventTrgtSid: '알람 타겟 ID',
      eventTrgtNm: '알람 타겟 이름',
      afectCh: '영향 채널',
      eventType: '알람 유형',
      eventState: '알람 상태',
      probableCause: '발생 원인',
      specificProblem: '상세 원인',
      perceivedSeverity: '등급',
      originSvrt: '기존 등급',
      eventGroupType: '알람 그룹 유형',
      eventCretMagent: '알람 생성 주체',
      prntEventYn: '부모 알람 유무',
      childEventYn: '자식 알람 유무',
      eventTime: '알람 시간',
      cleared: '해지 여부',
      acknowledgedTime: '인지 시간',
      clearanceTime: '해지 시간',
      trmnCause: '해지 원인',
      additionalInformation: '추가 INFO',
      additionalText: '추가 TEXT',
      operatorNote: '운영 NOTE',
      externalEventId: '외부알람 ID',
    },
  },
  standardInfo: {
    circuitInfo: {
      exchngNm: '관문국',
      cntryName: '국가명',
      businessName: '사업자명',
      circuitType: '회선구분',
      tgRouteNo: '루트번호',
      tgRouteName: '루트명',
      signalingSpot: '신호점',
      apc: 'APC',
      gnocTieName: 'GNOC 타이명',
      linkType: '연동방식',
      realm: 'Realm',
      host: 'Host ',
    },
    c3: {
      eventId: '알람코드',
      eventNm: '알람명',
      problCause: '발생원인',
      clearEventId: '클리어 알람코드',
      svrt: '알람등급',
      alarmYn: '가청여부',
      useYn: '사용여부',
      usrgrpSid: '문자전송그룹',
      updDt: '수정일시',
      updUser: '수정자',
    },
    stp: {
      eventId: '알람코드',
      eventNm: '알람명',
      problCause: '발생원인',
      clearEventId: '클리어 알람코드',
      svrt: '알람등급',
      alarmYn: '가청여부',
      useYn: '사용여부',
      usrgrpSid: '문자전송그룹',
      updDt: '수정일시',
      updUser: '수정자',
    },
    ein: {
      eventId: '알람코드',
      eventNm: '알람명',
      problCause: '발생원인',
      clearEventId: '클리어 알람코드',
      svrt: '알람등급',
      alarmYn: '가청여부',
      useYn: '사용여부',
      usrgrpSid: '문자전송그룹',
      updDt: '수정일시',
      updUser: '수정자',
    },
  },
  management: {
    hostInfoMgmt: {
      hostNm: '호스트명',
      ksmsIp: 'IP',
      serviceNm: '서비스',
      teamNm: '운용팀',
      platformNm: '플랫폼',
      os: 'OS',
      updDt: '업데이트 일시',
      capacity: '설계용량',
      apiNm: '서비스API 명',
      etc: '비고',
    },
    certificateMgmt: {
      hostNm: '호스트',
      platformNm: '플랫폼',
      teamNm: '운용팀',
      certificateType: '인증서 구분',
      description: '자재내역',
      domain: '도메인',
      manager: '담당',
      wafYn: 'WAF 수용여부',
      wafNm: 'WAF 명',
      sslStDt: 'SSL 유효기간 시작일',
      sslEdDt: 'SSL 유효기간 만료일',
      wafStDt: 'WAF 유효기간 시작일',
      wafEdDt: 'WAF 유효기간 만료일',
    },
  },
};

export enum DIALOG_TYPE {
  INSERT = 'insert',
  UPDATE = 'update',
  DETAIL = 'detail',
  UPDATE_BATCH = 'insertBlock',
  PLATFORM_MGMT = 'platformMgmt',
  OPERATION_TEAM = 'operationTeam',
}

export enum RESULT_CODE {
  SUCCESS = 'S',
  FAIL = 'F',
}

export enum BUTTON_LABEL {
  CLOSE = '닫기',
  ABLE_LOGIN = '로그인방지해제',
  WAKEUP = '휴면해제',
  REFUSAL = '승인거절',
  APPROVED = '승인완료',
  DELETE = '삭제',
  INSERT = '등록',
  UPDATE = '수정',
  CONFIRM = '확인',
  SAVE = '저장',
  CANCLE = '취소',
  SEARCH = '검색',
  ADD = '추가',
  EXPORT_PDF = 'PDF 내보내기',
  TEST = '테스트',
  ACK = 'ACK',
  PURGE = 'Purge',
  TERMINATE = 'Terminate',
  CLEAR = 'Clear',
  ATTACH_FILE = '파일 첨부',
  INSPECTION_HISTORY = '점검이력',
  ALARM_DETAILS = '알람 상세보기',
  CHANGE_PASSWORD = '비밀번호 변경',
  ID_DUPLICATE_CHECK = 'ID 중복확인',
  GRP_DUPLICATE_CHECK = '그룹 이름 중복확인',
  DUPLICATE_CHECK = '중복 확인',
  CHANNEL_LINE_REGISTER = '채널 선번장 등록',
  CHANNEL_LINE_TEMPLATE_DOWNLOAD = '템플릿 다운로드',
  CHANNEL_LINE_DOWNLOAD = '채널 선번장 다운로드',
  CHANNEL_HISTORY = '채널 변경이력',
  LOGIN = 'Login',
  KDB = 'KDB',
  EVENT_SELECT = '알람 선택',
  EVENT_DETAIL = '알람 선택',
  HOSTNM_DUPLICATE_CHECK = '호스트명 중복확인',
  RPA_TEMPLATE_DETAILS = 'RPA 템플릿 상세보기',
  RPA_TEMPLATE_SELECT = 'RPA 템플릿 선택',
  EQUIPMENT_DETAILS = '장비 상세보기',
  EQUIPMENT_SELECT = '장비 선택',
  EQUIPMENT_MAIN_SELECT = '대표장비 선택',
  EQUIPMENT_PAIRINSERT = '장비 생성',
  EQUIPMENT_PAIRUPDATE = '대상장비 수정',
  SERVER_DETAIL = '서버 상세보기',
  SERVER_SELECT = '서버 선택',
  INTERACTIVESYS_DETAIL = 'NMS 상세보기',
  INTERACTIVESYS_SELECT = 'NMS 선택',
  PP_SELECT = 'PP사 선택',
  SAID_DUPLICATE_CHECK = 'SAID 중복확인',
  ALARM_SELECT = '알람 선택',
  CONNECTION_DETAILS = 'Connection 상세보기',
  RUNNING_CONSOLE_CONFIRM = '실행 콘솔 확인',
  STOP_RUNNING = '실행 중지',
  RE_RUNNING = '재실행',
  STOP_TEST = '테스트 중지',
  TRANSFER_HISTORY = '절체이력',
  COPY = '복사',
  MIB_UPLOAD = 'MIB 등록',
  COPY_M_TO_B = '입력값 복사(M->B)',
  INIT_DATA_SAVE = '초기 데이터 저장',
  EQUIP_GROUP_INSERT = '장비 그룹 등록',
  EQUIP_GROUP_UPDATE = '장비 그룹 수정',
  CHANNEL_UPDATE = '채널정보 갱신',
  UPLOAD = '업로드',
  DOWNLOAD = '다운로드',
  EQUIP_TEMPLATE_DOWNLOAD = '장비템플릿 다운로드',
  EQUIP_UPLOAD = '장비업로드',
  RE_SEND = '재전송',
  DATA_UPDATE = '연동 데이터 갱신',
  EXCEL_DOWNLOAD = '엑셀 다운로드',
  EXCEL_INSERT = '일괄 등록/수정',
  PLATFORM_MGMT = '플랫폼 조직 관리',
  OPRTNTMNM_DUPLICATE_CHECK = '운용팀명 중복확인',
  SERVICE_ADD = '서비스 추가',
  PLATFORM_ADD = '플랫폼 추가',
  EXCEPT_INSERT = '제외등록',
  EXCEPT_CANCLE = '제외취소',
}
