import { alpha, Button, Card, Checkbox, Chip, Dialog, Fab, FormControlLabel, FormGroup, Slide, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { HEADER, SPACING } from 'src/config-global';
import Image from '../../components/image';
import { Suspense, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from 'src/models/root-store/root-store-context';
import { QrCodeScan } from './QrCodeScan';
import { blue } from '@mui/material/colors';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Privacy } from 'src/routes/elements';
import CarouselReport from './CarouselReport';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { load, loadString, remove } from 'src/utils/storage';
import { CallApiToStore } from 'src/utils/common';
import { toJS } from 'mobx';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { IReportSnapshot } from 'src/models/report/Report';
import Analysis from './analysis/Analysis';
/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
export const Report = observer(() => {

  const theme = useTheme();
  const rootStore = useStores();
  const { reportStore, userStore, loadingStore } = rootStore;

  let reportData = [...Array(0)].map((_, index) => ({
    id: 'id-' + index,
    title: 'MASTER 남성',
    subtitle: '유전적 위험도 검사',
    orderid: '202009101613' + index,
    image: '/assets/images/report/carousel_placeholder.png',
    date: '2023.01.1' + index,
  }));

  // reportData = [];

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [scanMode, setScanMode] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [open, setOpen] = useState(reportData.length < 1);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openAnalysis, setOpenAnalysis] = useState(false);

  const getReports = async () => {
    if (userStore.user.userId !== '') {
      await CallApiToStore(reportStore.gets(), 'api', loadingStore).then((r: any) => {
        console.log(toJS(reportStore.reports))
      });
    }
  }

  useEffect(() => {
    if (reportStore.metaGene.length < 1) {
      reportStore.getMetaGene();
    }
    getReports().then(async () => {
      if (params.get('latest') === 'true') {
        const res = JSON.parse(reportStore.registResult);
        reportStore.setRegistResult('');
        if (res.success === true) {
          setTimeout(() => {
            CallApiToStore(reportStore.getLatest(), 'api', loadingStore).then((res) => {
              setOpenAnalysis(true);
            });
          }, 500)
        } else {
          switch (res.type) {
            case 'registred':
              alert(res.message)
              getDetail(res.order_id);
              break;
            case 'duplicate':
              alert(res.message)
              getDetail(res.order_id);
              break;
            default:
              console.log(' # ', res.order_id, ',', res.message)
              alert('[' + res.order_id + ']\n' + res.message)
              break;
          }
        }
      }
    });
  }, []);

  // after direct regist
  useEffect(() => {
    if (reportStore.registResult !== '') {
      (async () => {
        const res = JSON.parse(reportStore.registResult);
        reportStore.setRegistResult('');
        if (res.success === true) {
          CallApiToStore(reportStore.gets(), 'api', loadingStore).then((res) => {
            CallApiToStore(reportStore.getLatest(), 'api', loadingStore).then((res) => {
              setOpenAnalysis(true);
            });
          });
        } else {
          switch (res.type) {
            case 'registred':
              alert(res.message)
              getDetail(res.order_id);
              break;
            case 'duplicate':
              alert(res.message)
              getDetail(res.order_id);
              break;
            default:
              console.log(' # ', res.order_id, ',', res.message)
              alert('[' + res.order_id + ']\n' + res.message)
              break;
          }
        }
      })()
    }
  }, [reportStore.registResult]);

  const getDetail = (orderId: string) => {
    CallApiToStore(reportStore.get(orderId), 'api', loadingStore).then((res) => {
      setOpenAnalysis(true);
    });
  }

  useEffect(() => {
    setOpen(userStore.user.userId === '')
  }, [reportStore.updated]);


  // TODO 삭제할것
  useEffect(() => {
    CallApiToStore(reportStore.getLatest(), 'api', loadingStore).then((res) => {
      setOpenAnalysis(true);
    });
  }, [reportStore.updated])

  useEffect(() => {
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: '#214bb2'
      }
    });
    sendReactNativeMessage({
      type: 'bottomBackgroundChange',
      payload: {
        color: '#FFFFFF'
      }
    });
    return () => {
      sendReactNativeMessage({
        type: 'topBackgroundChange',
        payload: {
          color: '#FFFFFF'
        }
      });
    };
  }, [])

  const analysisClose = () => {
    setOpenAnalysis(false);
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: '#214bb2'
      }
    })
  }

  return (
    // scanMode ? !navigator.mediaDevices ? <Typography>현재 카레마를 지원하지 않습니다.</Typography> : <QrCodeScan /> :
    <>
      <Stack
        sx={{
          position: 'relative',
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          m: 'auto',
          width: '100%',
          maxWidth: theme.breakpoints.only('lg'),
          minHeight: '100vh',
          maxHeight: `calc(100vh - ${(HEADER.H_MOBILE * 2) + SPACING}px)`,
          textAlign: 'center',
          justifyContent: 'center',
          background: '#214bb2',
        }}
      >

        <Stack
          sx={{
            position: 'absolute',
            top: {
              xl: '10vh',
              lg: '10vh',
              xs: '15vh'
            },
            left: 0,
            zIndex: 2,
            width: '100%',
            // border: '1px solid red',
            textAlign: 'left',
          }}>

          <Stack sx={{
            pl: { xs: 12, md: 16 },
            pr: { xs: 12, md: 16 },
            mb: { xs: 3, md: 4 },
            overflow: 'hidden',
          }}>
            <Link to="/"><Image src={'/assets/images/report/avata_placeholder.png'} borderRadius={'50%'} maxWidth={{ md: '5vw', xs: '12vw' }} disabledEffect /></Link>
            <Typography variant={'body1'} sx={{ color: '#FFFFFF', fontSize: { xs: '1rem', md: '1.4rem', xl: '2rem' } }}>{userStore.user.userName}님</Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant={'body1'} sx={{ color: '#FFFFFF', fontSize: { xs: '1rem', md: '1.4rem', xl: '2rem' } }}>안녕하세요.</Typography>
              {
                (reportStore.reports.length > 0 && currentSlide - 1 < reportData.length) &&
                <Box sx={{ color: '#FFF', fontSize: '.7rem', pt: .8 }}>
                  {currentSlide} <span style={{ color: alpha(theme.palette.grey[100], .5), fontSize: '.6rem' }}> / {reportData.length}</span>
                </Box>
              }
            </Stack>

          </Stack>

          <CarouselReport data={reportStore.reports} onScan={() => {
            sendReactNativeMessage({
              type: 'scan',
              payload: {
                type: 'scan',
              }
            })
          }}
            currentSlide={(n: number) => setCurrentSlide(n)}
            openPrivacy={() => setOpenPrivacy(true)}
            getDetail={getDetail}
          />

        </Stack>

        <Card sx={{
          position: 'absolute',
          zIndex: 1,
          bottom: 0,
          background: '#f7f7f7',
          borderRadius: 4,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          width: '100%',
          maxWidth: theme.breakpoints.only('lg'),
          height: { xl: '70vh', lg: '65vh', md: '55vh', xs: '57vh' }
        }} />

      </Stack>

      <Dialog
        // fullWidth={fullWidth}
        maxWidth={'md'}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
      >
        <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', alignContent: 'center', overflow: 'hidden' }}>
          <Box sx={{ width: '90%', margin: 'auto', p: 4 }}>
            <Image src={'/assets/images/report/pdf_sample.png'} disabledEffect ratio={'9/16'} />
          </Box>
          <Typography variant='h5' color={'secondary'} sx={{ mt: 0, mb: 2, p: 2 }}>결과지 맨 뒷장의 QR코드를 스캔하세요.</Typography>
          <Stack direction={'row'} sx={{ mb: '-2px', pb: '2px' }}>
            <Button variant={'contained'} color={'inherit'} size={'large'} sx={{ flex: .5, borderRadius: 0 }} onClick={() => setOpen(false)}>취소</Button>
            <Button variant={'contained'} color={'secondary'} size={'large'} sx={{ flex: .5, borderRadius: 0, backgroundColor: blue[600] }}
              onClick={() => {
                setOpen(false);
                sendReactNativeMessage({
                  type: 'scan',
                  payload: {
                    type: 'scan'
                  }
                })
              }}
            >QR 코드 스캔</Button>
          </Stack>
        </Box>
      </Dialog>

      <Dialog
        fullWidth
        keepMounted
        maxWidth={'lg'}
        open={openPrivacy}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            p: 0,
            m: 0,
            maxHeight: '100%',
            minHeight: '100%',
            borderRadius: '0 !important',
            '@media (max-width: 600px)': {
              margin: 0
            }
          }
        }}
        onClose={() => setOpenPrivacy(false)}
        sx={{ margin: '0 !important', zIndex: 9999999, padding: 0, borderRadius: 0 }}
      >

        <Privacy handleClose={() => setOpenPrivacy(false)} />
      </Dialog>

      <Dialog
        fullWidth
        keepMounted
        maxWidth={'lg'}
        open={openAnalysis}
        TransitionComponent={Transition2}
        PaperProps={{
          sx: {
            p: 0,
            m: 0,
            maxHeight: '100%',
            minHeight: '100%',
            borderRadius: '0 !important',
            '@media (max-width: 600px)': {
              margin: 0
            }
          }
        }}
        onClose={analysisClose}
        sx={{ margin: '0 !important', zIndex: 9999999, padding: 0, borderRadius: 0 }}
      >
        {
          openAnalysis &&
          <Analysis handleClose={analysisClose} />
        }
      </Dialog>
    </>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Transition2 = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default Report;