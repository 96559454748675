import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AuthStore, AuthStoreModel } from '../../models/auth-store/auth-store';
import { LanguageStore, LanguageStoreModel } from '../../models/language-store/language-store';
import { UserStore, UserStoreModel } from '../../models/user-store/user-store';
import { ErrorAlertStore, IErrorAlertStore } from '../error-alert-store/ErrorAlertStore';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';
import { IReportStore, ReportStore } from '../report-store/ReportStore';
import { IResponseStore, ResponseStore } from '../response-store/ResponseStore';
import { ITokStore, TokStore } from '../tok-store/TokStore';

// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStoreModel, {} as UserStore),
  authStore: types.optional(AuthStoreModel, {} as AuthStore),
  languageStore: types.optional(LanguageStoreModel, {} as LanguageStore),
  errorAlertStore: types.optional(ErrorAlertStore, {} as IErrorAlertStore),
  responseStore: types.optional(ResponseStore, {} as IResponseStore),
  loadingStore: types.optional(LoadingStore, {} as ILoadingStore),
  tokStore: types.optional(TokStore, {} as ITokStore),
  reportStore: types.optional(ReportStore, {} as IReportStore),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> { }

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> { }
