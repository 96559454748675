import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Model description here for TypeScript hints.
 */

export const UserModel = types
  .model('User')
  .props({
    userSid: types.maybeNull(types.optional(types.number, 0)),
    userId: types.maybeNull(types.optional(types.string, '')),
    userToken: types.maybeNull(types.optional(types.string, '')),
    userName: types.optional(types.string, '젠톡'),
  })
  .views((self) => ({}))
  .actions((self) => ({
    resetUser: () => {
      self.userSid = 0;
      self.userId = '';
      self.userToken = '';
      self.userName = '';
    },

    setProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as IUser;
      (Object.keys(updated) as TUserTypeKey[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          //@ts-ignore
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
  }));

type TUserType = Instance<typeof UserModel>;
type TUserSnapshotType = SnapshotOut<typeof UserModel>;

export interface IUser extends TUserType { }
export type TUserTypeKey = keyof TUserSnapshotType & string;
export interface IUserSnapshot extends TUserSnapshotType { }
export const createUserDefaultModel = () =>
  types.optional(UserModel, {
  });
