import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { TGeneralApiResponse } from './../../services/api/api-problem';
import { withRootStore } from './../extensions/with-root-store';
import { createResponse, IResponse } from './../response/Response';

/**
 * # ResponseStore
 *
 * ResponseStore을 설명하세요.
 */
export const ResponseStore = types
  .model('ResponseStore')
  // --------------------------------------------------------------------------
  .props({
    responseInfo: createResponse(),
    backLocation: types.optional(types.string, `/`),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  .actions((self) => ({
    setBackLocation: (backLocation: string) => {
      self.backLocation = backLocation;
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    getResponseResult: (result: TGeneralApiResponse) => {
      self.responseInfo.reset();
      if (result.kind === 'ok') {
        self.responseInfo = result.responseInfo as IResponse;
        if (self.responseInfo.resultCode === 'F') {
          if (self.responseInfo.errorCode === 'CAI-0998') {
            self.rootStore.authStore.logout();
          } else {
            self.rootStore.errorAlertStore.setErrorAlertFromResponse();
            return false;
          }
        }
        return true;
      } else if (result.kind === 'unauthorized') {
        self.rootStore.authStore.logout();
      }
    },

    getResponseResultWithOutSnackBar: (result: TGeneralApiResponse) => {
      self.responseInfo.reset();
      if (result.kind === 'ok') {
        self.responseInfo = result.responseInfo as IResponse;
        if (self.responseInfo.resultCode === 'F') {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    errorProcessing: (error: any) => {
      console.log('errorProcessing', error);
      self.responseInfo.reset();
    },

    // setResponse: <T>(resultType: T) => {},
  }));
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
type TResponseStore = Instance<typeof ResponseStore>;
type TResponseStoreSnapshot = SnapshotOut<typeof ResponseStore>;

export interface IResponseStore extends TResponseStore { }
export type TResponseStoreKeys = keyof TResponseStoreSnapshot & string;
export interface IResponseStoreSnapshot extends TResponseStoreSnapshot { }
export const createResponseStore = () => types.optional(ResponseStore, {} as TResponseStore);
