import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
export const CustomViewFinder = observer(() => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;

  return (
    <div className="custom-viewfinder">
      <div className="scanner-border-top" />
      <div className="scanner-border-right" />
      <div className="scanner-border-bottom" />
      <div className="scanner-border-left" />
      <div className="scanner-line" />
    </div>
  );
});

export default CustomViewFinder;