import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { PositiveVariants } from "../positive-variants/PositiveVariants";

/**
 * # TraitPathogenic
 *
 * TraitPathogenic을 설명하세요.
 */
export const TraitPathogenic = types
  .model("TraitPathogenic")
  // --------------------------------------------------------------------------
  .props({
    positiveVariants: types.optional(types.array(PositiveVariants), []),
    score: types.optional(types.number, 0),
    screenedVariants: types.optional(types.number, 0),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model traitPathogenic --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITraitPathogenic;
      (Object.keys(newProps) as TTraitPathogenicKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TTraitPathogenic = Instance<typeof TraitPathogenic>;
type TTraitPathogenicSnapshot = SnapshotOut<typeof TraitPathogenic>

export interface ITraitPathogenic extends TTraitPathogenic { }
export type TTraitPathogenicKeys = keyof TTraitPathogenicSnapshot & string;
export interface ITraitPathogenicSnapshot extends TTraitPathogenicSnapshot { }
export const createTraitPathogenic = () =>
  types.optional(TraitPathogenic, {
  } as TTraitPathogenic);
