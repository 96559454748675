import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # RsDesc
 *
 * RsDesc을 설명하세요.
 */
export const RsDesc = types
  .model("RsDesc")
  // --------------------------------------------------------------------------
  .props({

    descShort: types.optional(types.string, ''),
    geneName: types.optional(types.string, ''),
    location: types.optional(types.string, ''),
    rsName: types.optional(types.string, ''),
    traitCode: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rsDesc --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRsDesc;
      (Object.keys(newProps) as TRsDescKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRsDesc = Instance<typeof RsDesc>;
type TRsDescSnapshot = SnapshotOut<typeof RsDesc>

export interface IRsDesc extends TRsDesc { }
export type TRsDescKeys = keyof TRsDescSnapshot & string;
export interface IRsDescSnapshot extends TRsDescSnapshot { }
export const createRsDesc = () =>
  types.optional(RsDesc, {
  } as TRsDesc);
