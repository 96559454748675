import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IMetaAppSnapshot, IMetaEnv, IMetaEnvSnapshot, IMetaGene, IMetaGeneSnapshot, IMetaTrait, IMetaTraitSnapshot, IReport, IReportSnapshot, IVersionDetail, useStores } from '../../models';
import {
  TGetReportsResult,
  TGetReportResult,
  TPostReportResult,
  TUpdateReportResult,
  TDeleteReportResult,
  TGetMetaResult
} from "./ReportTypes";
import { IResponseSnapshot } from "src/models/response/Response";
import moment from 'moment';
import { loadString } from "src/utils/storage";

/**
* # Report Api 서비스
*
* 서비스의 설명을 작성하세요.
*
* ## 사용방법
*
* ```ts
* const service = new ReportApi();
*
* ```
*/

interface userProps {
  userId: string;
  userToken: string;
}
export class ReportApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }


  async gets(user: userProps): Promise<TGetReportsResult> {
    try {
      const url = 'reports';
      const params = {};
      const userId = user.userId;// await loadString('userId');
      const token = user.userToken;//await loadString('userToken');

      if (userId && token) {
        this.api.apisauce.setHeader('User-Id', userId.toString());
        this.api.apisauce.setHeader('Token', token.toString());
      } else {
        console.log('empty user info.');
        return { kind: 'bad-data' };
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;

      if (response.data.reportList) {
        let reports: IReportSnapshot[] = response.data.reportList;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          reports: reports as IReport[],
        };
      }

      return { kind: 'unauthorized' };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getMetaApp(): Promise<TGetMetaResult> {
    try {
      const url = 'appMeta';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (response.data.geneList) {
        let genes: IMetaAppSnapshot = response.data;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          versions: genes.versionDetail as IVersionDetail[],
        };
      }
      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getMetaGene(): Promise<TGetMetaResult> {
    try {
      const url = 'meta/gene';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (response.data.geneList) {
        let genes: IMetaGeneSnapshot[] = response.data.geneList;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          genes: genes as IMetaGene[],
        };
      }
      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getMetaTrait(): Promise<TGetMetaResult> {
    try {
      const url = 'meta/trait';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      console.log('🌈 ~ ReportApi ~ getMetaTrait ~ response', response)
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (response.data.traitList) {
        let traits: IMetaTraitSnapshot[] = response.data.traitList;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          traits: traits as IMetaTrait[],
        };
      }
      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async getMetaEnv(): Promise<TGetMetaResult> {
    try {
      const url = 'meta/env';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (response.data.envList) {
        let envs: IMetaEnvSnapshot[] = response.data.envList;
        return {
          kind: `ok`,
          responseInfo: responseInfo,
          envs: envs as IMetaEnv[],
        };
      }
      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async get(id: string): Promise<TGetReportResult> {
    try {
      const url = `https://gentok4test.appspot.com/api/m/v1/report/${id}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          response.data.data.reported_date = moment(response.data.data.reported_date).format('YYYY.MM.DD');
          response.data.data.receipted_date = moment(response.data.data.receipted_date).format('YYYY.MM.DD');
          const report: IReportSnapshot = response.data.data;

          return { kind: `ok`, responseInfo: responseInfo, report: report };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }

  async getFavorite(orderId: number): Promise<any> {
    try {
      const url = `https://gentok4test.appspot.com/api/m/v1/favorite?order_id=${orderId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      console.log('🌈 ~ ReportApi ~ getFavorite ~ response', response)
      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        if (response.data.favoriteCardnewsList) {
          const favorite: any = response.data.favoriteCardnewsList;

          return { kind: `ok`, responseInfo: responseInfo, favorite: favorite };
        }
      }
      return { kind: `ok`, responseInfo: responseInfo };
    } catch (e) {
      return { kind: `bad-data` };
    }
  }


  async post(): Promise<TPostReportResult> {
    try {
      const url = '/report';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const report: IReport = response.data.results;
      return { kind: "ok", report };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async update(id: number): Promise<TUpdateReportResult> {
    try {
      const url = `/report/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const report: IReport = response.data.results;
      return { kind: "ok", report };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async delete(user: userProps, order_id: number): Promise<TDeleteReportResult> {
    try {

      const userId = user.userId;
      const token = user.userToken;

      if (userId && token) {
        this.api.apisauce.setHeader('User-Id', userId.toString());
        this.api.apisauce.setHeader('Token', token.toString());
      } else {
        console.log('empty user info.');
        return { kind: 'bad-data' };
      }

      const url = `/report/${order_id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
