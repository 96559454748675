import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { HEADER, SPACING } from 'src/config-global';
import { useEffect, useState } from 'react';
import { useStores } from 'src/models/root-store/root-store-context';
import CarouselProduct from './CarouselProduct';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */

const geneStoreList = [
  {
    "discount": 0,
    "id": 3,
    "imageUrl": "https://storage.googleapis.com/gentok4test/store/img_microbeme.png",
    "isDtc": true,
    "linkUrl": "https://smartstore.naver.com/mygenomestory/products/4448724880",
    "name": "\ub9c8\uc774\ud06c\ub85c\ube0c\uc564\ubbf8",
    "price": 189000.0,
    "remark": "\ub098\ub9cc\uc758 \uc7a5 \uac74\uac15 \uad00\ub9ac\ubc95\uc744 \ucc3e\uae30 \uc704\ud55c \uc7a5\ub0b4 \ubbf8\uc0dd\ubb3c \uac80\uc0ac!"
  }, {
    "discount": 0,
    "id": 2,
    "imageUrl": "https://storage.googleapis.com/gentok4test/store/img_theplus.png",
    "isDtc": true,
    "linkUrl": "https://smartstore.naver.com/mygenomestory/products/4840761209",
    "name": "\ub9c8\uc774\uc9c0\ub188\uc2a4\ud1a0\ub9ac The Plus",
    "price": 198000.0,
    "remark": "\ubcd1\uc6d0\uc744 \uac00\uc9c0 \uc54a\uace0 \uc9d1\uc5d0\uc11c \uac04\ud3b8\ud558\uac8c \ub098\ub9cc\uc758 \uac74\uac15\ud50c\ub798\ub108\ub97c \ub9cc\ub098 \ubcf4\uc138\uc694."
  }, {
    "discount": 0,
    "id": 1,
    "imageUrl": "https://storage.googleapis.com/gentok4test/store/img_green.png",
    "isDtc": false,
    "linkUrl": null,
    "name": "\ub9c8\uc774\uc9c0\ub188\uc2a4\ud1a0\ub9ac GREEN",
    "price": 0.0,
    "remark": "\uc2e4\ud328 \uc5c6\ub294 \ub2e4\uc774\uc5b4\ud2b8 \ubc29\ubc95\uacfc \ub0b4\uac8c \ud544\uc694\ud55c \uc601\uc591\uc18c\ub97c \ud655\uc778\ud574\ubcf4\uc138\uc694."
  }
];

interface IProduct {
  discount: number,
  id: number,
  imageUrl: string,
  isDtc: boolean;
  linkUrl: string | null,
  name: string,
  price: number,
  remark: string
}

export const Product = observer(() => {

  const theme = useTheme();
  const rootStore = useStores();
  const { loadingStore } = rootStore;


  const { REACT_APP_API_URL } = process.env;

  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState<IProduct[]>([]);

  const getData = async () => {

    const res = await fetch(REACT_APP_API_URL + 'gene_store')
      .then(async (res: any) => {
        const json = await res.json();
        setProductList(json.geneStoreList);
        setLoading(false);
      }).catch((e: any) => {
        setProductList(geneStoreList);
        setLoading(false);
      });
  };

  useEffect(() => {

    getData();

  }, []);

  return (
    <>
      <Stack
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          m: 'auto',
          width: '100%',
          maxWidth: theme.breakpoints.only('lg'),
          minHeight: '100vh',
          maxHeight: `calc(100vh - ${(HEADER.H_MOBILE * 2) + SPACING}px)`,
          textAlign: 'center',
          justifyContent: 'top',
          background: '#FFFFFF',
        }}
      >

        <Typography variant={'h4'} sx={{ textAlign: 'left', pl: 4 }}>추천드리는 상품</Typography>

        {
          loading ?
            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><CircularProgress sx={{ width: 70, height: 70 }} color={'info'} /></Box>
            :
            <Stack sx={{ width: '100%', textAlign: 'left', mt: 7 }}>
              <CarouselProduct data={productList} />
            </Stack>
        }

      </Stack>

    </>
  );
});

export default Product;