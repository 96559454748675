import { createContext, useEffect, useContext, useMemo, useCallback, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import localStorageAvailable from '../utils/localStorageAvailable';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useStores } from 'src/models/root-store/root-store-context';
import { uuidForGentok } from 'src/utils/uuidv4';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { IUserSnapshot } from 'src/models/user/user';
import { useNavigate } from 'react-router-dom';
import { loadString, remove, save, saveString } from 'src/utils/storage';
import { isObject } from 'lodash';

//
// ----------------------------------------------------------------------

export type CommonContextProps = {
  // Mode
  onReportRegist: (data: any) => void;
  onReportRegistDirect: (data: any) => void;
};

const initialState: CommonContextProps = {
  onReportRegist: () => { },
  onReportRegistDirect: () => { },
};

// ----------------------------------------------------------------------

export const CommonContext = createContext(initialState);

export const useCommonContext = () => {
  const context = useContext(CommonContext);

  if (!context) throw new Error('useCommonContext must be use inside CommonProvider');

  return context;
};

// ----------------------------------------------------------------------

type CommonProviderProps = {
  children: React.ReactNode;
};

interface Message {
  type: string;
  payload?: any;
}

export function CommonProvider({ children }: CommonProviderProps) {

  const [value, setValue] = useState(false);
  const rootStore = useStores();
  const { userStore, reportStore } = rootStore;

  const cheerio = require('cheerio');
  const { REACT_APP_API_URL } = process.env;
  // const { userStore } = useStores();
  const navigate = useNavigate();

  const listener = async (event: any) => {

    // console.log('🌈 ~ listener ~ event.data', event.data)
    let data: any;
    try {
      if (isObject(event.data)) {
        console.log('Passed Object ::: ', event.data);
        return;
      }

      if (!isObject(event.data)) {
        data = JSON.parse(event.data) as Message;
      }
    } catch (e) {
      console.log('e', event, e);
    }
    if (!data.payload) {
      console.log('empty payload.');
      return;
    }
    // const history = useHistory();
    try {

      if (typeof event.data === 'string') {

        if (data.type === 'os') { // phone OS type

          userStore.setOS((data.payload.isIOS === true) ? 'ios' : 'android')

        } else if (data.type === 'initUser') { // from app

          const json = data.payload;
          userStore.setUser({
            userId: json.userId,
            userName: json.userName,
            userToken: json.userToken
          } as IUserSnapshot);

          if (json.userId !== '') {
            navigate('/report');
          }

        } else if (data.type === 'qrcodeDetected') { // QR 찍고 본인인증으로 이동

          console.log('data.payload', data.payload)

          userStore.veriftInfo(data.payload);

          const json = JSON.parse(data.payload);

          userStore.setOS(json.os)

          const order_id = json.rawValue.split('id=')[1].replace('\"', '');
          userStore.setOrderId(order_id);

          if (userStore.user.userId !== '') {
            onReportRegistDirect({
              order_id: order_id,
              json: json
            });
          } else {
            navigate('/agree', { replace: false });
          }

        } else if (data.type === 'verifySuccess') { // 휴대폰 인증결과 -> 성공후

          userAuth(data.payload.result);
          navigate('/user/adding', { replace: true });

        } else if (data.type === 'verifyFailed') { // 휴대폰 인증결과 -> 성공후

          // navigate('/', { replace: true });

        } else if (data.type === 'addNick') { // 휴대폰 인증결과 -> 성공후

          navigate('/user/nick', { replace: true });

        } else if (data.type === 'withdrawal') { // 내정보 삭제 -> 확인

          const userId = userStore.user.userId;
          const userToken = userStore.user.userToken;

          if (userId && userToken) {
            const res = fetch(REACT_APP_API_URL + 'users', {
              method: 'DELETE',
              headers: {
                'User-Id': userId,
                'Token': userToken,
              }
            }).then(async (r) => {
              userStore.setUser({
                userId: '',
                userName: '젠톡',
                userToken: ''
              } as IUserSnapshot)
              sendReactNativeMessage({
                type: 'onWithdrawal',
                payload: {}
              })
            }).catch((e) => {
              console.log('🌈 ~ listener ~ e', e)
            })
          } else {
            sendReactNativeMessage({
              type: 'onWithdrawal',
              payload: {}
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      if (isAndroid) {
        document.addEventListener('message', listener);
        console.log('Listen message..')
      }
      if (isIOS) {
        console.log('Listen message..')
        window.addEventListener('message', listener);
      }

      sendReactNativeMessage({
        type: 'initUser',
        payload: null
      })
    }

  }, []);

  /**
   * 본인인증 실패 :: app webview -> web
   * @param data
   */
  const verify_failed = (data: Message) => {

  }

  /**
   *
   * @param data QR 찍고 인증
   */
  const userAuth = async (data: any) => {
    const url = REACT_APP_API_URL + 'users_auth';
    // console.log('🌈 ~ userAuth ~ data', data)
    const body = JSON.stringify({
      'req_token': data.uuid,
      'req_num': data.reqNum,
      'di': data.DI,
      'birthyear': data.birthyear,
      'gender': Number(data.gender),
      'reqdate': data.reqdate
    });

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer wIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF', // Gentok <-> Agent token (fixed value)
      },
      body: body
    }).then(async (res: any) => {
      console.log('user auth result ; ', res);
      if (res.ok === true) {
        userRegist(data)
      }
    }).catch((e: any) => {
      console.log(e)
    })
  }

  /**
   * PASS 인증후 가입 -> reports 화면으로 이동
   * @param data
   */
  const userRegist = async (data: any) => {

    console.log('🌈 ~ userRegist ~ data', data)
    const url = REACT_APP_API_URL + 'users';
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Req-Token': data.uuid,
        'Req-Num': data.reqNum,
        'Di': data.DI
      },
    }).then(async (res: any) => {
      console.log('regist use result ; ', res);
      if (res.ok === true) {
        const text = await res.text();
        const result = JSON.parse(text);

        userStore.setUser({
          userId: result.userId,
          userName: data.name,
          userToken: result.token
        } as IUserSnapshot);

        console.log('set user userId ', result.userId)
        console.log('set user name ', data.name)
        console.log('set user token ', result.token)

        await remove('reportRegist');
        await save('reportRegist', {
          result: result,
          uuid: data.uuid,
          order_id: userStore.order_id,
          confirm_token: data.uuid,
          phone_number: data.phone
        });

        sendReactNativeMessage({
          type: 'userRegist',
          payload: {
            userId: result.userId,
            userName: data.name,
            token: result.token
          }
        });
        // navigate('/user/nick', { replace: true });

      }

    }).catch((e: any) => {
      console.log(e)
    })

  }

  const onReportRegist = async (data: any) => {

    const url = REACT_APP_API_URL + 'report2/auth';
    const order_id = data.order_id
    if (order_id === '') {
      console.log('empty order_id !!');
      return;
    }

    const body: any = {
      order_id: order_id,
      confirm_token: data.confirm_token,
      phone_number: data.phone_number
    };

    const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'User-Id': userStore.user.userId || '',
        'Token': userStore.user.userToken || '',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }).then(async (res: any) => {
      console.log('🌈 ~ reportRegist ~ res', res)

      let result = {} as { success: boolean, type: string, message: string, order_id: number };

      if (res.status === 409 || res.status === 403) {
        result = {
          success: false,
          type: 'registred',
          message: "이미 등록된 리포트입니다.",
          order_id: order_id
        }
        // } else if (res.status === 202) {
        //   result = {
        //     success: false,
        //     type: 'di error',
        //     message: "di does not match.",
        //     order_id: order_id
        //   }
        // } else if (res.status === 500) {
        //   result = {
        //     success: false,
        //     type: 'duplicate',
        //     message: "Duplicate entry",
        //     order_id: order_id
        //   }
        // } else if (res.status === 404) {
        //   result = {
        //     success: false,
        //     type: 'notFound',
        //     message: "not found",
        //     order_id: order_id
        //   }
      } else {
        result = {
          success: true,
          type: 'success',
          message: "",
          order_id: order_id
        }
      }

      reportStore.setRegistResult(JSON.stringify(result));

      navigate('/report?latest=true', { replace: true });

    }).catch((e: any) => {
      console.log('====================================');
      console.log(e)
      // navigate('/report?latest=true', { replace: true });
      let result = {
        success: true,
        type: 'success',
        message: "",
        order_id: order_id
      }
      reportStore.setRegistResult(JSON.stringify({ result }));
    })
  }

  const onReportRegistDirect = async ({ order_id, json }: any) => {

    const url = REACT_APP_API_URL + 'report';
    if (order_id === '') {
      console.log('empty order_id !!');
      return;
    }
    const body: any = {
      order_id: order_id,
      confirm_token: json.uuid,
    };

    const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'User-Id': userStore.user.userId || '',
        'Token': userStore.user.userToken || '',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }).then(async (res: any) => {
      console.log('====================================');
      console.log('🌈 ~ onReportRegistDirect ~ res', res)

      let result = {} as { success: boolean, type: string, message: string, order_id: number };

      if (res.status === 409 || res.status === 403) {
        result = {
          success: false,
          type: 'registred',
          message: "이미 등록된 리포트입니다.",
          order_id: order_id
        }
      } else if (res.status === 202) {
        result = {
          success: false,
          type: 'di error',
          message: "di does not match.",
          order_id: order_id
        }
      } else if (res.status === 500) {
        result = {
          success: false,
          type: 'duplicate',
          message: "Duplicate entry",
          order_id: order_id
        }
      } else if (res.status === 404) {
        result = {
          success: false,
          type: 'notFound',
          message: "not found",
          order_id: order_id
        }
      } else {
        result = {
          success: true,
          type: 'success',
          message: "",
          order_id: order_id
        }
      }

      reportStore.setRegistResult(JSON.stringify(result));

      if (json.latest) {
        navigate('/report?latest=true', { replace: true });
      }

    }).catch((e: any) => {
      console.log('====================================');
      console.log(e)
    })
  }

  const memoizedValue = useMemo(
    () => ({
      onReportRegist,
      onReportRegistDirect,
    }),
    [
      onReportRegist,
      onReportRegistDirect
    ]
  );
  return <CommonContext.Provider value={memoizedValue}>{children}</CommonContext.Provider>;
}
