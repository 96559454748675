import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { EmrAndSurveyItem } from '../emr-and-survey-item/EmrAndSurveyItem';

/**
 * # EmrAndSurvey
 *
 * EmrAndSurvey을 설명하세요.
 */
export const EmrAndSurvey = types
  .model("EmrAndSurvey")
  // --------------------------------------------------------------------------
  .props({
    items: types.array(EmrAndSurveyItem),
    summary: types.optional(types.number, 0),
    surveyed: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model emrAndSurvey --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEmrAndSurvey;
      (Object.keys(newProps) as TEmrAndSurveyKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEmrAndSurvey = Instance<typeof EmrAndSurvey>;
type TEmrAndSurveySnapshot = SnapshotOut<typeof EmrAndSurvey>

export interface IEmrAndSurvey extends TEmrAndSurvey { }
export type TEmrAndSurveyKeys = keyof TEmrAndSurveySnapshot & string;
export interface IEmrAndSurveySnapshot extends TEmrAndSurveySnapshot { }
export const createEmrAndSurvey = () =>
  types.optional(EmrAndSurvey, {
  } as TEmrAndSurvey);
