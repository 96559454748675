import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const Home = Loadable(lazy(() => import('../screens/home/Home')));
export const Login = Loadable(lazy(() => import('../screens/login/Login')));
export const Register = Loadable(lazy(() => import('../screens/register/Register')));
export const ComingSoon = Loadable(lazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(lazy(() => import('../screens/common/Maintenance')));
export const Page403 = Loadable(lazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(lazy(() => import('../screens/common/Page500')));
export const Report = Loadable(lazy(() => import('../screens/report/Report')));
export const QrCodeScan = Loadable(lazy(() => import('../screens/report/QrCodeScan')));
export const Product = Loadable(lazy(() => import('../screens/product/Product')));
export const HealthTok = Loadable(lazy(() => import('../screens/health-tok/HealthTok')));
export const Bookmark = Loadable(lazy(() => import('../screens/health-tok/bookmark/Bookmark')));
export const Tok = Loadable(lazy(() => import('../screens/health-tok/tok/Tok')));
export const User = Loadable(lazy(() => import('../screens/user/User')));
export const AgreeInfo = Loadable(lazy(() => import('../screens/user/agree-info/AgreeInfo')));
export const Identification = Loadable(lazy(() => import('../screens/user/identification/Identification')));
export const Privacy = Loadable(lazy(() => import('../screens/common/privacy/Privacy')));
export const Collect = Loadable(lazy(() => import('../screens/common/collect/Collect')));
export const Terms = Loadable(lazy(() => import('../screens/common/terms/Terms')));
export const CheckPhone = Loadable(lazy(() => import('../screens/user/check-phone/CheckPhone')));
export const AddNick = Loadable(lazy(() => import('../screens/user/add-nick/AddNick')));
