import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # MetaGene
 *
 * MetaGene을 설명하세요.
 */
export const MetaGene = types
  .model("MetaGene")
  // --------------------------------------------------------------------------
  .props({
    id: types.number,
    category: types.string,
    descShort: types.string,
    geneName: types.string,
    isPathogenic: types.string,
    location: types.string,
    traitCode: types.string,
    traitName: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model metaGene --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMetaGene;
      (Object.keys(newProps) as TMetaGeneKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMetaGene = Instance<typeof MetaGene>;
type TMetaGeneSnapshot = SnapshotOut<typeof MetaGene>

export interface IMetaGene extends TMetaGene { }
export type TMetaGeneKeys = keyof TMetaGeneSnapshot & string;
export interface IMetaGeneSnapshot extends TMetaGeneSnapshot { }
export const createMetaGene = () =>
  types.optional(MetaGene, {
  } as TMetaGene);
