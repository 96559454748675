import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CallApiToStore } from 'src/utils/common';
import { IReportSnapshot } from 'src/models';
import DocIcon from '@mui/icons-material/DescriptionOutlined';
import { useTheme } from '@mui/material';
import moment from 'moment';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { useNavigate } from 'react-router';
import { CloseIcon } from 'yet-another-react-lightbox/core';
import { Document, Page } from 'react-pdf';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
type Props = {
  handleClose: VoidFunction;
}
export const ReportInfo = observer(({
  handleClose
}: Props) => {

  const rootStore = useStores();
  const { reportStore, userStore, loadingStore } = rootStore;
  const theme = useTheme();

  useEffect(() => {
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: '#FFFFFF'
      }
    });
    return () => {
    }
  }, [])

  const [pdfUrl, setPdfUrl] = useState('')
  const [openPdf, setOpenPdf] = useState(false)
  const [snedRemove, setSnedRemove] = useState(false)


  const onLoadSuccess = () => {

  }

  const { REACT_APP_API_URL } = process.env;

  const [selectOrderId, setSelectedOrderId] = useState<number>();
  const [openConfirn, setOpenConfirn] = useState(false)
  const removeReport = async () => {
    if (selectOrderId) {
      setSnedRemove(true);
      CallApiToStore(reportStore.remove(selectOrderId), 'api', loadingStore).then((r) => {
        setSnedRemove(false);
        setOpenConfirn(false);
        getList();
      })
    }
  }

  const getList = () => {
    CallApiToStore(reportStore.gets(), 'api', loadingStore).then(() => {
    });
  }

  return (
    <>

      <Box sx={{
        position: 'relative',
        background: '#FFFFFF',
        textAlign: 'center',
        borderBottom: '1px solid #e5e5e5',
        p: 2,
      }}>
        <IconButton sx={{ position: 'absolute', left: 8, top: 12 }} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
        <Typography variant={'h5'}>내 리포트 정보</Typography>
      </Box>
      <Box sx={{ display: 'flex', flex: 1, overflowX: 'hidden', justifyContent: 'center' }}>
        {
          reportStore.reports.length < 1 ?
            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>등록된 리포트가 없습니다.</Box>
            :
            <Stack flex={1}>
              {reportStore.reports.map((report: IReportSnapshot, i: number) => {
                return (
                  <Box key={`reoprt-${report.orderId}-${i}`} sx={{ position: 'relative', width: '100%', borderTop: '1px solid #e6e6e6', p: 3 }}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ mb: 2 }}>
                      <Typography variant={'body1'} sx={{ fontSize: '.9rem' }}>{report.productName}</Typography>
                      <Button size={'small'} variant={'outlined'}
                        sx={{ borderRadius: 4, color: theme.palette.text.disabled, height: 24, fontSize: '.7rem' }}
                        onClick={() => {
                          window.location.href = report.pdfUrl;
                        }}
                      ><DocIcon sx={{ fontSize: '.8rem', mr: .5, }} />분석 안내서</Button>
                    </Stack>
                    <Stack direction={'row'}>
                      <Typography variant={'body2'} color={'GrayText'} sx={{ minWidth: 100 }}>샘플번호</Typography>
                      <Typography variant={'body2'} color={'GrayText'}>{report.orderId}</Typography>
                    </Stack>
                    <Stack direction={'row'}>
                      <Typography variant={'body2'} color={'GrayText'} sx={{ minWidth: 100 }}>검사받은 날짜</Typography>
                      <Typography variant={'body2'} color={'GrayText'}>{moment(report.reportedDate).format('YYYY.MM.DD')}</Typography>
                    </Stack>
                    <Stack direction={'row'}>
                      <Typography variant={'body2'} color={'GrayText'} sx={{ minWidth: 100 }}>검사받은 병원</Typography>
                      <Typography variant={'body2'} color={'GrayText'}>{report.hospitalCode}</Typography>
                    </Stack>
                    <Button size={'small'} variant={'outlined'}
                      sx={{
                        borderRadius: 4, color: '#F00', height: 24, fontSize: '.7rem',
                        position: 'absolute', right: 24, bottom: 24
                      }}
                      onClick={() => {
                        setOpenConfirn(true)
                        setSelectedOrderId(Number(report.orderId))
                      }}>삭제</Button>
                  </Box>
                )
              })}
            </Stack>
        }
      </Box>

      <Dialog
        open={openPdf} onClose={() => setOpenPdf(false)}
        fullWidth
        sx={{ margin: '0 !important', zIndex: 9999999, width: '100%' }}>
        <DialogTitle sx={{ pl: 4 }}>
          분석 안내서
          <IconButton
            aria-label="close"
            onClick={() => setOpenPdf(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0, minWidth: 340 }}>
          <Box sx={{ p: 4, pt: 0 }}>
            <Document file={pdfUrl} onLoadSuccess={onLoadSuccess}>
              <Page pageNumber={1} />
            </Document>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirn}
        maxWidth={'md'}
        sx={{ margin: '0 !important', zIndex: 9999999, width: '100%' }}>
        <DialogTitle sx={{ p: 4 }}>
          리포트 삭제
        </DialogTitle>
        <DialogContent sx={{ p: 4 }}>
          해당 리포트를 삭제하시겠습니까?<br />
          삭제한 리포트는 QR코드 등록을 통해<br />
          다시 확인이 가능합니다
        </DialogContent>
        <DialogActions>
          <Button disabled={snedRemove} onClick={() => {
            setOpenConfirn(false);
            handleClose();
          }} color="primary">
            취소
          </Button>
          <Button disabled={snedRemove} onClick={() => {
            !snedRemove && removeReport()
          }} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ReportInfo;