import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from "../extensions/with-root-store";
import {
  ReportApi,
  TDeleteReportResult,
  TGetMetaResult,
  TGetReportResult,
  TGetReportsResult
} from "../../services";
import { createReport, IReport, IReportSnapshot, Report } from "../report/Report";
import moment from 'moment';
import { toJS } from "mobx";
import { IMetaGene, IMetaGeneSnapshot, MetaGene } from '../meta-gene/MetaGene';
import { IMetaTrait, IMetaTraitSnapshot, MetaTrait } from '../meta-trait/MetaTrait';
import { IMetaEnv, IMetaEnvSnapshot, MetaEnv } from "../meta-env/MetaEnv";
import { IVersionDetail, IVersionDetailSnapshot, VersionDetail } from "../version-detail/VersionDetail";

/**
 * # ReportStore
 *
 * ReportStore을 설명하세요.
 */
export const ReportStore = types
  .model("ReportStore")
  // --------------------------------------------------------------------------
  .props({
    reports: types.optional(types.array(Report), []),
    report: createReport(),
    updated: types.optional(types.number, 0),
    registResult: types.optional(types.string, ''),
    metaApp: types.optional(types.array(VersionDetail), []),
    metaGene: types.optional(types.array(MetaGene), []),
    metaTrait: types.optional(types.array(MetaTrait), []),
    metaEnv: types.optional(types.array(MetaEnv), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * reports을 교체
     *
     * @param `reports` 새로운 모델의 배열
     */
    setReports: (reports: IReportSnapshot[]) => {
      console.log('🌈 ~ .actions ~ reports', reports)
      try {
        self.reports.replace(reports as IReport[]);
      } catch (e) {
        console.log(e)
      }
    },
    setMetaApp: (versions: IVersionDetailSnapshot[]) => {
      self.metaApp.replace(versions as IVersionDetail[]);
    },
    setMetaGene: (genes: IMetaGeneSnapshot[]) => {
      self.metaGene.replace(genes as IMetaGene[]);
    },
    setMetaTrait: (traits: IMetaTraitSnapshot[]) => {
      self.metaTrait.replace(traits as IMetaTrait[]);
    },
    setMetaEnv: (envs: IMetaEnvSnapshot[]) => {
      self.metaEnv.replace(envs as IMetaEnv[]);
    },
    setReport: (report: IReportSnapshot) => {
      self.report = report as IReport;
    },
    setUpdated: () => {
      self.updated++;
    },
    setRegistResult: (str: string) => {
      self.registResult = str
    }
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Reports를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const reportApi: ReportApi = new ReportApi(self.environment.api);
      const result: TGetReportsResult = await reportApi.gets({
        userId: self.rootStore.userStore.user.userId || '',
        userToken: self.rootStore.userStore.user.userToken || '',
      });

      if (result.kind === 'ok') {
        self.setReports(result.reports?.reverse() || []);
        self.setUpdated();
      } else {
        console.error(result.kind);
      }
    },

    getMetaGene: async () => {
      const reportApi: ReportApi = new ReportApi(self.environment.api);
      try {
        let res: TGetMetaResult;
        res = await reportApi.getMetaApp();
        if (res.kind === 'ok' && res.versions) {
          console.log('🌈 ~ getMetaGene: ~ res.versions', res.versions)
          self.setMetaApp(res.versions);
        }
        res = await reportApi.getMetaGene();
        if (res.kind === 'ok' && res.genes) {
          console.log('🌈 ~ getMetaGene: ~ res.gene', res.genes)
          self.setMetaGene(res.genes);
        }
        res = await reportApi.getMetaTrait();
        if (res.kind === 'ok' && res.traits) {
          console.log('🌈 ~ getMetaGene: ~ res.traits', res.traits)
          self.setMetaTrait(res.traits);
        }
        res = await reportApi.getMetaEnv();
        if (res.kind === 'ok' && res.envs) {
          console.log('🌈 ~ getMetaGene: ~ res.envs', res.envs)
          self.setMetaEnv(res.envs);
        }
      } catch (e) {
        console.log(e)
      }

    },

    getLatest: async () => {
      self.setReport(toJS(self.reports[0]));
    },

    get: async (orderId: string) => {
      self.reports.map((report: IReportSnapshot) => {
        if (report.orderId === orderId) {
          self.setReport(toJS(report));
        }
      })
    },

    remove: async (orderId: number) => {
      try {
        const reportApi: ReportApi = new ReportApi(self.environment.api);
        const result: TDeleteReportResult = await reportApi.delete({
          userId: self.rootStore.userStore.user.userId || '',
          userToken: self.rootStore.userStore.user.userToken || '',
        }, orderId);
        self.rootStore.responseStore.getResponseResult(result);
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }

    },

    getFavorite: async (orderId: number) => {

      const reportApi: ReportApi = new ReportApi(self.environment.api);
      const result: any = await reportApi.getFavorite(orderId);
      // if (result.kind === 'ok') {
      //   if (result.genes) {
      //     self.setMetaGene(result.genes);
      //   }
      //   const result2: TGetMetaResult = await reportApi.getMetaTrait();
      //   if (result2.traits) {
      //     self.setMetaTrait(result2.traits);
      //     const result3: TGetMetaResult = await reportApi.getMetaEnv();
      //     if (result3.envs) {
      //       self.setMetaEnv(result3.envs);
      //     }
      //   }
      // } else {
      //   console.error(result.kind);
      // }
    }
  }))

// --------------------------------------------------------------------------
type TReportStore = Instance<typeof ReportStore>;
type TReportStoreSnapshot = SnapshotOut<typeof ReportStore>

export interface IReportStore extends TReportStore { }
export type TReportStoreKeys = keyof TReportStoreSnapshot & string;
export interface IReportStoreSnapshot extends TReportStoreSnapshot { }
export const createReportStore = () =>
  types.optional(ReportStore, {} as TReportStore);
