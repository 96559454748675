import { CircularProgress, Typography, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
export const Adding = observer(() => {

  const rootStore = useStores();
  const { userStore, loadingStore } = rootStore;

  return (
    <>
      <Box sx={{
        position: 'relative',
        background: '#FFF',
        textAlign: 'center',
        p: 2
      }}>
        <Typography variant={'h5'}>인증</Typography>
      </Box>
      {
        userStore.user.userId === '' && (
          <Stack sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ width: 70, height: 70, mb: 2 }} color={'info'} />
            <Typography variant={'subtitle1'}>등록중입니다..</Typography>
          </Stack>)
      }
    </>
  );
});

export default Adding;