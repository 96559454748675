import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { ITok } from '../../models';
import {
  TGetToksResult,
  TGetTokResult,
  TPostTokResult,
  TUpdateTokResult,
  TDeleteTokResult
} from "./TokTypes";

/**
* # Tok Api 서비스
*
* 서비스의 설명을 작성하세요.
*
* ## 사용방법
*
* ```ts
* const service = new TokApi();
*
* ```
*/
export class TokApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(): Promise<TGetToksResult> {
    try {
      const url = '/health_tok';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      // alert('response : ' + JSON.stringify(response))
      // sendReactNativeMessage({
      //   type: 'data',
      //   payload: {
      //     data: response.data
      //   }
      // })
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const toks: ITok[] = response.data.results;
      return { kind: "ok", toks };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async get(id: number): Promise<TGetTokResult> {
    try {
      const url = `/tok/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const tok: ITok = response.data.results;
      return { kind: "ok", tok };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async post(): Promise<TPostTokResult> {
    try {
      const url = '/tok';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const tok: ITok = response.data.results;
      return { kind: "ok", tok };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async update(id: number): Promise<TUpdateTokResult> {
    try {
      const url = `/tok/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const tok: ITok = response.data.results;
      return { kind: "ok", tok };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async delete(id: number): Promise<TDeleteTokResult> {
    try {
      const url = `/tok/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: "ok" };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}