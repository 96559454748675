import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # CardioEnvItem
 *
 * CardioEnvItem을 설명하세요.
 */
export const CardioEnvItem = types
  .model("CardioEnvItem")
  // --------------------------------------------------------------------------
  .props({
    idx: types.optional(types.number, 0),
    risk: types.optional(types.number, 0),
    class: types.optional(types.string, ''),
    code: types.optional(types.string, ''),
    envIcon: types.optional(types.string, ''),
    korean: types.optional(types.string, ''),
    relative: types.optional(types.string, ''),
    unit: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model cardioEnvItem --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICardioEnvItem;
      (Object.keys(newProps) as TCardioEnvItemKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCardioEnvItem = Instance<typeof CardioEnvItem>;
type TCardioEnvItemSnapshot = SnapshotOut<typeof CardioEnvItem>

export interface ICardioEnvItem extends TCardioEnvItem { }
export type TCardioEnvItemKeys = keyof TCardioEnvItemSnapshot & string;
export interface ICardioEnvItemSnapshot extends TCardioEnvItemSnapshot { }
export const createCardioEnvItem = () =>
  types.optional(CardioEnvItem, {
  } as TCardioEnvItem);
