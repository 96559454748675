import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # PositiveVariants
 *
 * PositiveVariants을 설명하세요.
 */
export const PositiveVariants = types
  .model("PositiveVariants")
  // --------------------------------------------------------------------------
  .props({
    gene: types.string,
    snp: types.string,
    traitCode: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model positiveVariants --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPositiveVariants;
      (Object.keys(newProps) as TPositiveVariantsKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPositiveVariants = Instance<typeof PositiveVariants>;
type TPositiveVariantsSnapshot = SnapshotOut<typeof PositiveVariants>

export interface IPositiveVariants extends TPositiveVariants { }
export type TPositiveVariantsKeys = keyof TPositiveVariantsSnapshot & string;
export interface IPositiveVariantsSnapshot extends TPositiveVariantsSnapshot { }
export const createPositiveVariants = () =>
  types.optional(PositiveVariants, {
  } as TPositiveVariants);
