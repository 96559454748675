import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # VersionDetail
 *
 * VersionDetail을 설명하세요.
 */
export const VersionDetail = types
  .model("VersionDetail")
  // --------------------------------------------------------------------------
  .props({
    code: types.number,
    name: types.string,
    type: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model versionDetail --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IVersionDetail;
      (Object.keys(newProps) as TVersionDetailKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TVersionDetail = Instance<typeof VersionDetail>;
type TVersionDetailSnapshot = SnapshotOut<typeof VersionDetail>

export interface IVersionDetail extends TVersionDetail { }
export type TVersionDetailKeys = keyof TVersionDetailSnapshot & string;
export interface IVersionDetailSnapshot extends TVersionDetailSnapshot { }
export const createVersionDetail = () =>
  types.optional(VersionDetail, {
  } as TVersionDetail);
