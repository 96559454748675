import { BottomNavigation, BottomNavigationAction, Paper, styled, useTheme } from "@mui/material";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import StorefrontIcon from '@mui/icons-material/StorefrontOutlined';
import BookmarkIcon from '@mui/icons-material/BookmarkBorderOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Link, useLocation } from "react-router-dom";

export default function Footer() {

    const theme = useTheme();
    // const [value, setValue] = useState();

    const { pathname } = useLocation();

    console.log('pathname', pathname)

    const iconSx = { stroke: "#ffffff", strokeWidth: 1 };
    const defaultColor = { color: '#999999' }
    const activeColor = { color: '#3478f6' }

    return (
        <Paper sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999999,
            // maxWidth: theme.breakpoints.values.lg,
        }} elevation={3}>
            <BottomNavigation
                showLabels={false}
                // value={value}
                // onChange={(event, newValue) => {
                //     setValue(newValue);
                // }}
                sx={{
                    bgcolor: '#FFFFFF',
                }}
            >
                <BottomNavigationAction label="" icon={<HomeIcon fontSize="large" sx={{ ...iconSx, ...(pathname === '/report' ? activeColor : defaultColor) }} />} component={Link} to={'/report'} />
                <BottomNavigationAction label="" icon={<StorefrontIcon fontSize="large" sx={{ ...iconSx, ...(pathname === '/store' ? activeColor : defaultColor) }} />} component={Link} to={'/store'} />
                <BottomNavigationAction label="" icon={<BookmarkIcon fontSize="large" sx={{ ...iconSx, ...(pathname === '/tok' ? activeColor : defaultColor) }} />} component={Link} to={'/tok'} />
                <BottomNavigationAction label="" icon={<PersonIcon fontSize="large" sx={{ ...iconSx, ...(pathname === '/user/info' ? activeColor : defaultColor) }} />} component={Link} to={'/user'} />
            </BottomNavigation>
        </Paper>
    );
}
