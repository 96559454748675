import { ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from '../../services/api';
import {
  GetDuplicateRegisterResult,
  GetLoginTypeResult,
  GetLoginUserInfoResult,
  GetRegisterResult, LoginResponseTypeResult,
} from '../../services/auth/auth-result-types';
import { Api } from '../api/api';
import { IResponseSnapshot } from './../../models/response/Response';
import { IUserSnapshot } from './../../models/user/user';
import { ILoginResponse } from "../../models/login-response/LoginResponse";


export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async logout(): Promise<any> {
    try {
      const URL: string = `/logout`;

      const response: ApiResponse<any> = await this.api.apisauce.get(URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return {
          kind: 'ok',
          responseInfo: responseInfo,
          user: response.data.data,
        };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async getLoginUserInfo(): Promise<GetLoginUserInfoResult> {
    try {
      const URL: string = `/login/user`;

      const response: ApiResponse<any> = await this.api.apisauce.get(URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          return {
            kind: 'ok',
            responseInfo: responseInfo,
            user: response.data.data,
          };
        }
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  /**
   * 회원 가입 API
   * @param user
   * @returns
   */
  async register(user: IUserSnapshot): Promise<GetRegisterResult> {
    try {
      const SUB_URL = `/user`;
      return { kind: 'ok', result: false };
    } catch (e) {
      return {
        kind: 'notOk',
      };
    }
  }

  /**
   * 사용자 ID 중복검사 API
   * @param userId
   * @returns
   */
  async checkDuplicate(userId: string): Promise<GetDuplicateRegisterResult> {
    try {
      const USER_ID = userId;
      const SUB_URL = `/user/isDuplicatedUserId?usrId=${USER_ID}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      let result = response.data.responseData.resultCode;

      //아이디 중복일 경우 FAIL / 중복이 아닐 경우 OK
      return { kind: 'ok', result: result === 'FAIL' };
    } catch (e) {
      return {
        kind: 'notOk',
      };
    }
  }

  async getLoginType(id: string, pw: string): Promise<GetLoginTypeResult> {
    try {
      const url: string = `/login/user`;
      let form = new FormData();
      form.append('userId', id);
      form.append('userPwd', pw);
      //x-www-urlencoded
      await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
      const respopnse: ApiResponse<any> = await this.api.apisauce.post(url, form);

      const responseInfo: IResponseSnapshot = respopnse.data;
      if (responseInfo.resultCode === 'S') {
        const result: boolean = respopnse.data.data;
        return { kind: 'ok', result: result, responseInfo: responseInfo };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async loginProcess(id: string, password: string, type: string): Promise<LoginResponseTypeResult> {
    try {
      const url: string = `/loginProcess`;
      const formData = new FormData();
      formData.append("userId", id);
      formData.append('userPwd', password);
      formData.append("type", type);
      const data = {
        userId: id,
        userPwd: password,
        type: type
      };
      await this.api.apisauce.setHeader('Content-Type', 'application/x-www-form-urlencoded');
      const response: ApiResponse<any> = await this.api.apisauce.post(url, formData);
      await this.api.apisauce.setHeader('Content-Type', 'application/json');

      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        const result: ILoginResponse = response.data.data;
        return { kind: 'ok', loginResponse: result, responseInfo: responseInfo };
      }

      return { kind: 'ok', responseInfo: responseInfo };

    } catch (e) {
      return { kind: 'bad-data' }
    }
  };
}
