import { observer } from 'mobx-react-lite';
import { alpha, Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'src/components/carousel';
import { HEADER, SPACING } from 'src/config-global';
import { Bookmark, Tok } from 'src/routes/elements';
import { useStores } from "../../models"
import useResponsive from 'src/hooks/useResponsive';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
export const HealthTok = observer(() => {

  const theme = useTheme();
  const rootStore = useStores();
  const { loadingStore } = rootStore;

  const isUser = true;

  const bookmarkData = [...Array(12)].map((_, index) => ({
    id: 'id-' + index,
    title: '전립선암',
    subtitle: '전립선암의 관리팁',
    link: 'go bookmark link',
  }));

  const tokData = [...Array(9)].map((_, index) => ({
    id: 'id-' + index,
    title: '골다공증',
  }));

  const activeStyle = {
    fontSize: '1rem'
  };

  const defaultStyle = {
    color: alpha(theme.palette.text.primary, .6)
  };

  const contentRef = useRef<any>(null);
  const carouselRef = useRef<Carousel | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselSettings = {
    dots: false,
    arrows: false,
    autoplay: false,
    draggable: false,
    slidesToShow: 1,
    // slidesToScroll: 1,
    // initialSlide: 1,
    rtl: false,
    speed: 200,
    infinite: false,
    easing: 'easeOut',
    centerMode: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
    contentRef.current?.scrollIntoView();
    // carouselRef.current?.innerSlider?.list?.scrollIntoView();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    contentRef.current?.scrollIntoView();
    // carouselRef.current?.innerSlider?.list?.scrollIntoView();
  };

  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Stack
        sx={{
          position: 'relative',
          pt: `${HEADER.H_MOBILE + SPACING + 16}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          m: 'auto',
          width: '100%',
          maxWidth: theme.breakpoints.only('lg'),
          minHeight: '100vh',
          maxHeight: `calc(100vh - ${(HEADER.H_MOBILE * 2) + SPACING}px)`,
          textAlign: 'center',
          justifyContent: 'flex-start',
          background: '#f5f6f8',
          overflowY: 'auto'
        }}
      >
        <Paper sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          p: 1,
          pl: 2,
          zIndex: 10,
          textAlign: isDesktop ? 'center' : 'left',
          justifyContent: 'start'
        }} elevation={1}>
          {
            isUser &&
            <Button variant={'text'} color={'inherit'} sx={{ ...(currentIndex === 0 ? activeStyle : defaultStyle) }} onClick={handlePrev}>즐겨찾기</Button>
          }
          <Button variant={'text'} color={'inherit'} sx={{ ...(currentIndex === 1 ? activeStyle : defaultStyle) }} onClick={handleNext}>헬스 Tok</Button>
        </Paper>

        <Box sx={{ p: 1, flex: 1, scrollMarginTop: '100px' }} ref={contentRef}>

          <Carousel ref={carouselRef} {...carouselSettings}>
            {
              isUser && <Stack><Bookmark data={bookmarkData} /></Stack>
            }
            <Stack>
              <Typography variant={'body1'} sx={{ color: alpha(theme.palette.text.primary, .6), fontSize: '.8rem', p: 1, pl: 2 }} textAlign={'left'}>
                젠톡에서 전해드리는 <br />건강정보 헬스톡입니다.
              </Typography>
              <Tok data={tokData} />
            </Stack>
          </Carousel>

        </Box>
      </Stack>
    </>
  );
});

export default HealthTok;
