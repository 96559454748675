import { isNull, isUndefined } from 'lodash';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../../models/extensions/with-environment';
import { IUser } from '../../models/user/user';
import { PATH_AUTH } from '../../routes/paths';
import { IAuthUser, IAuthUserSnapshot } from '../auth-user/AuthUser';
import { createAuthDefaultModel } from '../auth/Auth';
import { createLoginResponse, ILoginResponse } from '../login-response/LoginResponse';
import { AuthApi } from './../../services/auth/auth-api';
import {
  GetLoginResult,
  GetLoginTypeResult,
  GetLoginUserInfoResult, LoginResponseTypeResult,
} from './../../services/auth/auth-result-types';
import { withRootStore } from './../extensions/with-root-store';

/**
 * Model description here for TypeScript hints.
 */
export const LOGIN_USER_STORAGE_NM = 'loginUser';
export const LAST_VIEW_PATH = 'lastViewPath';
export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    errorMessage: types.optional(types.string, ''),
    isDuplicated: types.optional(types.boolean, false),
    authStatus: createAuthDefaultModel(),
    loginType: types.optional(types.boolean, false),
    loginResponse: createLoginResponse(),
    breadcrumbs: types.optional(types.string, '')
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  // MUTATEs
  .actions((self) => ({
    setIsDuplicated: (isDuplicated: boolean) => {
      self.isDuplicated = isDuplicated;
    },
    setErrorMessage: (errorMessage: string) => {
      self.errorMessage = errorMessage;
    },

    setLastViewPathStorageData: (path?: string) => {
      if (path) {
        window.localStorage.setItem(LAST_VIEW_PATH, path);
      } else {
        window.localStorage.removeItem(LAST_VIEW_PATH);
      }
    },

    processAfterLoginSuccess: async (user: IAuthUserSnapshot) => {
      window.localStorage.setItem(LOGIN_USER_STORAGE_NM, JSON.stringify(user));


      // // eslint-disable-next-line react-hooks/rules-of-hooks
      // const navigate = useNavigate();
      // navigate("/");

      window.location.href = window.localStorage.getItem(LAST_VIEW_PATH) || '/';
    },
    processAfterLogout: async () => {
      window.localStorage.removeItem(LOGIN_USER_STORAGE_NM);
      window.localStorage.removeItem(LAST_VIEW_PATH);
      self.rootStore.errorAlertStore.resetErrorAlert();
      window.location.href = PATH_AUTH.login;
    },

    getLoginUserStorageData: (): IAuthUserSnapshot | null => {
      const userStr: string | null = window.localStorage.getItem(LOGIN_USER_STORAGE_NM);
      if (userStr) {
        const user: IAuthUserSnapshot = JSON.parse(userStr) as IAuthUser;
        if (isNull(user?.userSid) || isUndefined(user?.userSid)) {
          return null;
        }
        return user;
      }
      return null;
    },
    setLoginType: (loginType: boolean) => {
      self.loginType = loginType;
    },
    setLoginResponse: (loginResponse: ILoginResponse) => {
      self.loginResponse = loginResponse;
    },
    setBreadcrumbs: (s: object) => {
      self.breadcrumbs = JSON.stringify(s);
    }
  }))
  // CALL REST APIs
  .actions((self) => ({
    /**
     * Login된 세션의 User 정보
     */
    getLoginUserInfo: async () => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: GetLoginUserInfoResult = await authApi.getLoginUserInfo();

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.user) {
            await self.processAfterLoginSuccess(result.user);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getLoginType: async (id: string, pw: string) => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: GetLoginTypeResult = await authApi.getLoginType(id, pw);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.result) {
            self.setLoginType(result.result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * Session방식의 Logout 함수입니다.
     * @param id
     */
    logout: async () => {
      const authApi: AuthApi = new AuthApi(self.environment.api);
      const result: GetLoginResult = await authApi.logout();

      await self.processAfterLogout();
    },
    loginProcess: async (id: string, pwd: string, type: string) => {
      try {
        const authApi: AuthApi = new AuthApi(self.environment.api);
        const result: LoginResponseTypeResult = await authApi.loginProcess(id, pwd, type);
        const loginResponse = result?.loginResponse;
        if (loginResponse) {
          self.setLoginResponse(loginResponse);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    // eslint-disable-line @typescript-eslint/no-unused-vars
    resetPassword: async (email: string) => { }, // eslint-disable-line @typescript-eslint/no-unused-vars
    updateProfile: async (user: IUser) => { }, // eslint-disable-line @typescript-eslint/no-unused-vars
  })); // eslint-disable-line @typescript-eslint/no-unused-vars

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType { }
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType { }
type AuthStoreTypeKey = keyof AuthStoreSnapshotType;
