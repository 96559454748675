import { useEffect, useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Paper, Link, Typography, Stack, Chip, Button } from '@mui/material';
import useResponsive, { useWidth } from 'src/hooks/useResponsive';
import Carousel, { CarouselArrows } from 'src/components/carousel';
import Image from 'src/components/image';
import { IReportSnapshot } from 'src/models';
import moment from 'moment';

// ----------------------------------------------------------------------

type Props = {
  data: IReportSnapshot[];

  onScan: VoidFunction;
  currentSlide: (current: number) => void;
  openPrivacy: VoidFunction;
  getDetail: (orderId: string) => void;
};

export default function CarouselReport({ data, onScan, currentSlide, openPrivacy, getDetail }: Props) {

  const carouselRef = useRef<Carousel | null>(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'xl');
  const isXl = useResponsive('up', 'xl');
  const breakpoints = useWidth();

  const carouselSettings = {
    rtl: false,
    dots: false,
    infinite: false,
    initialSlide: data.length < 1 ? data.length : 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    centerPadding: useResponsive('up', 'md') ? '340px' : '60px',
    speed: 400,
    easing: 'ease-out',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          // centerMode: true,
          slidesToShow: 1,
          centerPadding: '60px',
          speed: 400,
        },
      },
    ],
    // arrows: isDesktop ? true : false
    afterChange: (index: number) => {
      currentSlide(index + 1);
    }
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  const arrowIcon = (breakpoints === 'xl' || breakpoints === 'lg') ? 'noto:rightwards-hand' : '';
  const filled = (breakpoints === 'xl' || breakpoints === 'lg') ? true : false;

  return (
    <>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >

        <CarouselArrows
          filled
          onNext={handleNext}
          onPrevious={handlePrev}
          leftButtonProps={{
            sx: {
              display: isDesktop ? 'block' : 'none'
            }
          }}
          rightButtonProps={{
            sx: {
              display: isDesktop ? 'block' : 'none'
            }
          }}
        >
          <Carousel ref={carouselRef} {...carouselSettings}>
            {data.map((item: IReportSnapshot, index: number) => (
              <Box key={item.orderId + '-' + index} sx={{ px: 1 }}
                onClick={() => {
                  getDetail(item.orderId)
                }}>
                <CarouselItem item={item} />
              </Box>
            ))}
            <Box key={'scan-card'} sx={{ px: 1 }}>
              <Paper
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                  position: 'relative',
                  p: '2px',
                  textAlign: 'center'
                }}
              >
                <Image alt={'QR code scan'} src={'/assets/images/report/qr_placeholder2.png'} borderRadius={4} disabledEffect sx={{
                  border: `1px solid #DFDFDF`,
                  borderRadius: 4
                }}
                  ratio={(isDesktop ? '1/1' : '1/1')}
                  onClick={onScan}
                />
                <Stack sx={{
                  p: { xl: 6, lg: 4, xs: 4 },
                }}>
                  <Typography variant={'body2'} color={alpha(theme.palette.text.secondary, 0.6)}>리포트를 등록해주세요.</Typography>
                  <Box>
                    <Button variant={'outlined'} sx={{ color: theme.palette.text.primary, borderRadius: 4, mt: 1, mb: 1 }} onClick={onScan}>QR 코드 스캔</Button>
                  </Box>
                  <Button variant={'text'}
                    sx={{ color: alpha(theme.palette.text.secondary, 0.6), fontSize: '0.75rem' }}
                    onClick={openPrivacy}
                  >개인정보 처리방침</Button>
                </Stack>

              </Paper >
            </Box>
          </Carousel>
        </CarouselArrows>
      </Box >
    </>
  );
}

// ----------------------------------------------------------------------
function CarouselItem({ item }: { item: IReportSnapshot }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const { productName, productSubName, kitId, reportedDate } = item;
  const image = '/assets/images/report/carousel_placeholder.png';

  const guideName = productName.split(' ')[0].toLowerCase();

  return (
    <Paper
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        p: '2px',
      }}
    >

      <Chip color={'error'} label={'New'} size={'small'} sx={{ position: 'absolute', left: { xs: '8%', md: '5%' }, top: { xs: '4%', md: '3.5%' }, zIndex: 2 }} />

      {/* <Image alt={productName || ''} src={image} borderRadius={4} effect={'blur'} ratio={(isDesktop ? '1/1' : '1/1')} disabledEffect /> */}
      <Box sx={{
        borderRadius: 4,
        minHeight: { xs: 270, lg: 240 },
        background: bgUrl[guideName],
        backgroundSize: 'contain'
      }}></Box>

      <Stack sx={{
        p: { xl: 6, lg: 4, xs: 4 },
        pt: { xs: 3 }
      }}>
        <Typography variant={'h5'}>{productName}</Typography>
        <Typography variant={'body1'} sx={{ pb: 1 }}>{productSubName}</Typography>
        <Typography variant={'body2'}>{kitId}</Typography>
        <Typography variant={'body2'}>{moment(reportedDate).format('YYYY.MM.DD')}</Typography>
      </Stack>

    </Paper >
  );
}

const bgUrl: any = {
  master: 'url("https://storage.googleapis.com/gentok/zoom_mobile_page/imgMaster.svg") 100% 100% no-repeat rgb(230, 214, 255)',
  grand: 'url("https://storage.googleapis.com/gentok/zoom_mobile_page/imgGrand.png") 100% 100% no-repeat rgb(255, 249, 168)',
  standard: 'url("https://storage.googleapis.com/gentok/zoom_mobile_page/imgStandard.png") 100% 100% no-repeat rgb(234, 236, 239)',
}