import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Genotypes
 *
 * Genotypes을 설명하세요.
 */
export const Genotypes = types
  .model("Genotypes")
  // --------------------------------------------------------------------------
  .props({
    idx: types.optional(types.number, 0),
    allele: types.optional(types.string, ''),
    gene: types.optional(types.string, ''),
    otherAllele: types.optional(types.string, ''),
    probe: types.optional(types.string, ''),
    sample: types.optional(types.string, ''),
    snp: types.optional(types.string, ''),
    subs: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model genotypes --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IGenotypes;
      (Object.keys(newProps) as TGenotypesKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TGenotypes = Instance<typeof Genotypes>;
type TGenotypesSnapshot = SnapshotOut<typeof Genotypes>

export interface IGenotypes extends TGenotypes { }
export type TGenotypesKeys = keyof TGenotypesSnapshot & string;
export interface IGenotypesSnapshot extends TGenotypesSnapshot { }
export const createGenotypes = () =>
  types.optional(Genotypes, {
  } as TGenotypes);
