import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { CardioEnvItem } from "../cardio-env-item/CardioEnvItem";

/**
 * # CardioAndCerebroEnv
 *
 * CardioAndCerebroEnv을 설명하세요.
 */
export const CardioAndCerebroEnv = types
  .model("CardioAndCerebroEnv")
  // --------------------------------------------------------------------------
  .props({
    adjustedOverallRisk: types.optional(types.number, 0),
    envList: types.optional(types.array(CardioEnvItem), []),
    overallRisk: types.optional(types.number, 0)
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model cardioAndCerebroEnv --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICardioAndCerebroEnv;
      (Object.keys(newProps) as TCardioAndCerebroEnvKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCardioAndCerebroEnv = Instance<typeof CardioAndCerebroEnv>;
type TCardioAndCerebroEnvSnapshot = SnapshotOut<typeof CardioAndCerebroEnv>

export interface ICardioAndCerebroEnv extends TCardioAndCerebroEnv { }
export type TCardioAndCerebroEnvKeys = keyof TCardioAndCerebroEnvSnapshot & string;
export interface ICardioAndCerebroEnvSnapshot extends TCardioAndCerebroEnvSnapshot { }
export const createCardioAndCerebroEnv = () =>
  types.optional(CardioAndCerebroEnv, {
  } as TCardioAndCerebroEnv);
