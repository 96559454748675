import { useTheme } from '@emotion/react';
import { Masonry } from '@mui/lab';
import { Card, CardActionArea, CardContent, CardHeader, Dialog, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import useResponsive from 'src/hooks/useResponsive';
import Image from '../../../components/image';
import { useEffect, useState } from 'react';
import Popup from './Popup';
import { CallApiToStore } from 'src/utils/common';
import { useStores } from 'src/models/root-store/root-store-context';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */

type Props = {
  data: any[];
}
export const Tok = observer((
  { data }: Props
) => {

  const rootStore = useStores();
  const { tokStore, loadingStore, userStore } = rootStore;

  const theme = useTheme();

  const isLg = useResponsive('up', 'lg');

  const [imageList, setImageList] = useState<string[]>([]);
  const [openLightBox, setOpenLightBox] = useState(false);

  const [list, setList] = useState<{ id: number, imageUrlList: string[], thumbnail: string, title: string, background?: null }[]>([]);

  const onClick = (id: string, list: string[]) => {

    sendReactNativeMessage({
      type: 'backgroundChange',
      payload: {
        color: '#000000'
      }
    });

    setImageList(list)
    setOpenLightBox(true);
  }

  const { REACT_APP_API_URL } = process.env;

  const getData = async () => {
    let response = await fetch(REACT_APP_API_URL + 'health_tok', {
      method: 'GET',
      headers: {
        'User-Id': userStore.user.userId || '',
        'Token': userStore.user.userToken || '',
        // 'credentials': 'true',
      },
    }).then(async (r: any) => {
      const res: any = await r.json();
      setList(res.healthTokList);
    }).catch((e) => {
      setList(dummy.healthTokList)
    })
  }

  useEffect(() => {

    getData();

  }, []);

  const dummy = {
    healthTokList: [{
      "background": null,
      "id": 22,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1610580972/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610580972/10.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1610580972/01.jpg",
      "title": "\ubaa8\uae30 \uc798 \ubb3c\ub9ac\ub294 \uccb4\uc9c8"
    }, {
      "background": null,
      "id": 21,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1610579984/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/10.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1610579984/11.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1610579984/01.jpg",
      "title": "\ud1b5\uc99d"
    }, {
      "background": null,
      "id": 17,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135997/01.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135997/01.jpg",
      "title": "\ud06c\ub9ac\uc2a4\ub9c8\uc2a4"
    }, {
      "background": null,
      "id": 16,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135977/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135977/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135977/01.jpg",
      "title": "\uc704\uc554"
    }, {
      "background": null,
      "id": 15,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135944/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135944/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135944/01.jpg",
      "title": "\uac04\uc554"
    }, {
      "background": null,
      "id": 14,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135931/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135931/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135931/01.jpg",
      "title": "\ubd80\uc815\ub9e5"
    }, {
      "background": null,
      "id": 13,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135911/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135911/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135911/01.jpg",
      "title": "\uc720\uc804\uc790 \uac00\uc704"
    }, {
      "background": null,
      "id": 12,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135880/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135880/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135880/01.jpg",
      "title": "\ub178\ub85c\ubc14\uc774\ub7ec\uc2a4"
    }, {
      "background": null,
      "id": 11,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135858/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135858/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135858/01.jpg",
      "title": "\uac11\uc0c1\uc120\uc554"
    }, {
      "background": null,
      "id": 10,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135684/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135684/08.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135684/01.jpg",
      "title": "\ub1cc\ub3d9\ub9e5\ub958"
    }, {
      "background": null,
      "id": 9,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135668/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/10.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135668/11.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135668/01.jpg",
      "title": "\uce58\ub9e4 \uc720\uc804\uc790"
    }, {
      "background": null,
      "id": 8,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135642/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135642/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135642/01.jpg",
      "title": "\ube44\ud0c0\ubbfc D"
    }, {
      "background": null,
      "id": 7,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135626/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135626/09.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135626/01.jpg",
      "title": "\uace0\ud608\uc555"
    }, {
      "background": null,
      "id": 6,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135604/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135604/10.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135604/01.jpg",
      "title": "\ud3d0\ub834"
    }, {
      "background": null,
      "id": 5,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135587/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135587/10.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135587/01.jpg",
      "title": "\uc548\uad6c\uac74\uc870\uc99d"
    }, {
      "background": null,
      "id": 3,
      "imageUrlList": ["https://storage.googleapis.com/gentok4test/health_tok/1609135543/01.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/02.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/03.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/04.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/05.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/06.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/07.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/08.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/09.jpg", "https://storage.googleapis.com/gentok4test/health_tok/1609135543/10.jpg"],
      "thumbnail": "https://storage.googleapis.com/gentok4test/health_tok/1609135543/01.jpg",
      "title": "\ub3c5\uac10"
    }],
    "nextToken": null
  };

  const popupClose = () => {
    setOpenLightBox(false);
    sendReactNativeMessage({
      type: 'backgroundChange',
      payload: {
        color: '#FFFFFF'
      }
    });
  }
  return (
    <>
      <Masonry columns={{ xs: 2, md: 2, lg: 2 }} spacing={isLg ? 3 : 1.5} sx={{ p: 0, m: 0 }}>
        {
          list?.map((item: any, i: number) => {
            return (
              <TokItem key={'tok-' + item.id} item={item} idx={(i + 1)} returnClick={onClick} />
            )
          })
        }
      </Masonry>

      <Dialog
        fullScreen
        open={openLightBox}
        onClose={popupClose}
        // TransitionComponent={Transition}
        keepMounted
        sx={{ margin: '0 !important' }}
      >
        <Popup open={openLightBox} handleClose={popupClose} items={imageList} />
      </Dialog>
    </>
  );
});

export default Tok;

type TokItem = {
  id: string;
  title: string;
  imageUrlList: string[];
  thumbnail: string;
}
type TokProps = {
  item: TokItem;
  idx: number;
  returnClick: (id: string, list: string[]) => void;
};


function TokItem({ item, idx, returnClick }: TokProps) {

  return (
    <Card
      sx={{
        pb: 0,
        border: '1px solid #e3e3e3',
      }}
      onClick={() => returnClick(item.id, item.imageUrlList)}>
      <Box sx={{ p: 0, pb: 1.5, textAlign: 'center' }}>
        <Image src={item.thumbnail} alt={item.title} ratio={'1/1'} sx={{ borderRadius: 4, borderTopLeftRadius: 0, borderTopRightRadius: 0 }} disabledEffect />
        <Box sx={{
          p: 1, pb: 0, mb: 0
        }}>
          <Typography variant={'body2'} sx={{}}>{item.title}</Typography>
        </Box>
      </Box>
    </Card >
  );
}