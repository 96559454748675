import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { VersionDetail } from "../version-detail/VersionDetail";

/**
 * # MetaApp
 *
 * MetaApp을 설명하세요.
 */
export const MetaApp = types
  .model("MetaApp")
  // --------------------------------------------------------------------------
  .props({
    reportVersion: types.maybeNull(types.string),
    versionDetail: types.array(VersionDetail),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model metaApp --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMetaApp;
      (Object.keys(newProps) as TMetaAppKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMetaApp = Instance<typeof MetaApp>;
type TMetaAppSnapshot = SnapshotOut<typeof MetaApp>

export interface IMetaApp extends TMetaApp { }
export type TMetaAppKeys = keyof TMetaAppSnapshot & string;
export interface IMetaAppSnapshot extends TMetaAppSnapshot { }
export const createMetaApp = () =>
  types.optional(MetaApp, {
  } as TMetaApp);
