import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  ComingSoon,
  Maintenance,
  Page403,
  Page404,
  Page500,
  Home,
  Login,
  Register,
  Report,
  Product,
  Bookmark,
  User,
  AgreeInfo,
  Identification,
  QrCodeScan,
  HealthTok,
  CheckPhone,
  AddNick
} from './elements';
import MobileLayout from 'src/layouts/mobile';
import { Adding } from 'src/screens';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <MobileLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },

    // Main Routes
    {
      element: <MobileLayout />,
      children: [
        { element: <Home />, index: true },
        {
          path: 'report', element: <Report />,
          children: [
            { path: 'scan', element: <QrCodeScan /> },
          ]
        },
        { path: 'store', element: <Product /> },
        { path: 'tok', element: <HealthTok /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/info" replace />, index: true },
            { path: 'info', element: <User /> },
            { path: 'check-phone', element: <CheckPhone /> }, // 신규 사용자 큐알 찍고 난뒤 화면
            { path: 'adding', element: <Adding /> },
            { path: 'nick', element: <AddNick /> },
          ]
        },
      ],
    },
    {
      element: < SimpleLayout />,
      children: [
        { path: 'agree', element: <AgreeInfo /> },
        { path: 'identification', element: <Identification /> },
      ]
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '403', element: <Page403 /> },
        { path: '404', element: <Page404 /> },
        { path: '500', element: <Page500 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
