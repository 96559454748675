import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # MetaEnv
 *
 * MetaEnv을 설명하세요.
 */
export const MetaEnv = types
  .model("MetaEnv")
  // --------------------------------------------------------------------------
  .props({
    id: types.number,
    envCode: types.string,
    envDesc: types.string,
    envName: types.string,
    gender: types.string,
    image: types.string,
    traitCode: types.string,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model metaEnv --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMetaEnv;
      (Object.keys(newProps) as TMetaEnvKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMetaEnv = Instance<typeof MetaEnv>;
type TMetaEnvSnapshot = SnapshotOut<typeof MetaEnv>

export interface IMetaEnv extends TMetaEnv { }
export type TMetaEnvKeys = keyof TMetaEnvSnapshot & string;
export interface IMetaEnvSnapshot extends TMetaEnvSnapshot { }
export const createMetaEnv = () =>
  types.optional(MetaEnv, {
  } as TMetaEnv);
