import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { EmrAndSurvey, createEmrAndSurvey } from '../emr-and-survey/EmrAndSurvey';
import { Gwas } from "../gwas/Gwas";
import { createTraitPathogenic } from '../trait-pathogenic/TraitPathogenic';

/**
 * # Trait
 *
 * Trait을 설명하세요.
 */
export const Trait = types
  .model("Trait")
  // --------------------------------------------------------------------------
  .props({
    code: types.optional(types.string, ''),
    emrAndSurvey: createEmrAndSurvey(),
    english: types.optional(types.string, ''),
    gwas: Gwas,
    korean: types.optional(types.string, ''),
    pathogenic: createTraitPathogenic(),
    score: types.optional(types.number, 0),
    traitDesc: types.optional(types.string, ''),
    traitGraph: types.optional(types.string, ''),
    traitIcon: types.optional(types.string, ''),
    traitPathogenic: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model trait --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITrait;
      (Object.keys(newProps) as TTraitKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TTrait = Instance<typeof Trait>;
type TTraitSnapshot = SnapshotOut<typeof Trait>

export interface ITrait extends TTrait { }
export type TTraitKeys = keyof TTraitSnapshot & string;
export interface ITraitSnapshot extends TTraitSnapshot { }
export const createTrait = () =>
  types.optional(Trait, {
  } as TTrait);
