import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # RsValue
 * 
 * RsValue을 설명하세요.
 */
export const RsValue = types
  .model("RsValue")
  // --------------------------------------------------------------------------
  .props({
    id: types.string,
    name: types.string,
    optinalValue: types.optional(types.string, 'default'),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rsValue --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRsValue;
      (Object.keys(newProps) as TRsValueKeys[]).forEach((key) => {
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRsValue = Instance<typeof RsValue>;
type TRsValueSnapshot = SnapshotOut<typeof RsValue>

export interface IRsValue extends TRsValue {}
export type TRsValueKeys = keyof TRsValueSnapshot & string;
export interface IRsValueSnapshot extends TRsValueSnapshot {}
export const createRsValue = () => 
  types.optional(RsValue, {
    id: 'rsValue-id',
    name: 'rsValue-name',
  } as TRsValue);
