import Box from '@mui/material/Box';
import { Stack, useTheme } from '@mui/system';
import { observer } from "mobx-react-lite";
import { useStores } from "../../models/root-store/root-store-context"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Chip, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
interface Props {
  handleClose: VoidFunction
}

export const Commentary = observer(({
  handleClose
}: Props) => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;

  const theme = useTheme();

  const { REACT_APP_IMAGE_STORAGE } = process.env;

  useEffect(() => {
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: '#FFFFFF'
      }
    })
  }, [])

  const [tabValue, setTabValue] = useState('tab1');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    console.log('🌈 ~ handleTabChange ~ newValue', newValue)
    setTabValue(newValue);
  };

  const [chipIndex, setChipIndex] = useState(0);
  const chips = ['전체', '병원성 변이', '연관성 변이', '유전형', '유전적 위험도', '환경적 요인', '복합상대위험도'];

  return (
    <>
      <Box sx={{
        position: 'relative',
        background: '#FFF',
        textAlign: 'center',
        p: 2
      }}>
        <IconButton sx={{ position: 'absolute', left: 8, top: 11 }} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
        <Typography variant={'h5'}>해설 가이드</Typography>
      </Box>
      <TabContext value={tabValue}>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{ padding: 2, paddingTop: 0 }}>
          <Tab key={'tab1'} label={'결과 이해'} {...a11yProps(0)} value={'tab1'} />
          <Tab key={'tab2'} label={'용어 설명'} {...a11yProps(1)} value={'tab2'} />
          <Tab key={'tab3'} label={'서비스 소개'} {...a11yProps(2)} value={'tab3'} />
        </Tabs>
        <TabPanel key={'panel1'} value={tabValue} sx={{ p: 0, m: 0 }}>
          <Box sx={{ p: 2, background: '#f5f5f5', }}>
            {
              chips.map((chip, i: number) => (
                <Chip key={'chip-' + i} label={chip} sx={{ ...(chipIndex === i ? activeChip : defaultChip), mr: .6, mb: .6 }} />
              ))
            }
          </Box>
          <Stack sx={{ flex: 1, p: 2, m: 0, overflowX: 'hidden' }}>
            <Box sx={{ background: '#f5f5f5', }}>
              <Typography>asdf</Typography>
            </Box>

          </Stack>
        </TabPanel>
      </TabContext>
    </>
  );
});

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const defaultChip = {
  background: '#FFFFFF',
  color: '#555555',
  border: '1px solid #AAAAAA'
}
const activeChip = {
  background: '#000000',
  color: '#FFFFFF',
  border: '1px solid #AAAAAA'
}

export default Commentary;