import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import ReactLightbox, { Slide } from 'yet-another-react-lightbox';
import { useEffect, useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIos';
import { Typography } from '@mui/material';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
type popProps = {
  handleClose: VoidFunction;
  open: boolean;
  items?: string[];
  title?: string;
}
export const Popup = observer((
  {
    handleClose,
    open = false,
    items
  }: popProps
) => {

  const [current, setCurrent] = useState(1);

  return (
    <>
      <Box>
        <ReactLightbox
          key={'lightbox'}
          open={open}
          close={handleClose}
          animation={{ swipe: 300 }}
          carousel={{ preload: 2, finite: true, imageFit: 'contain', padding: 0 }}
          controller={{ closeOnBackdropClick: false }}
          plugins={[
            Thumbnails
          ]}
          thumbnails={{
            position: 'bottom',
            width: 24,
            height: 24,
            border: 1,
            borderRadius: 4,
            padding: 4,
            gap: 16
          }}
          render={{
            iconPrev: () => <></>,
            iconNext: () => <></>,
          }}
          toolbar={{
            buttons: [
              <Box key={'arrow-back'} sx={{ position: 'fixed', p: 3, left: 0, }} onClick={handleClose}>
                <ArrowLeftIcon key={'arrowBack'} sx={{ color: '#FFFFFF' }} />
              </Box>,
              <Typography
                key={'totalCount'}
                sx={{
                  position: 'fixed',
                  top: 30,
                  right: 10,
                  typography: 'body1',
                  textAlign: 'center',
                  color: '#FFF',
                }}
              >
                <strong>{current + 1} </strong> / {items?.length || 0}
              </Typography>
            ],
          }}
          slides={items?.map(item => {
            return {
              src: item
            };
          })}
          // slides={[
          //   { src: '/assets/images/tok/0/01.jpg' },
          // ]}
          on={{
            view: (index: number) => {
              setCurrent(index);
            },
          }}
          styles={{
            root: {
              background: '#000', zIndex: 9999999
            },
            container: {
              paddingTop: 100
            },
            thumbnailsContainer: {
              width: '100%',
              marginBottom: 20
            }
          }}
        />
      </Box>
    </>
  );
});

export default Popup;