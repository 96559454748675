import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Button, Card, Chip, Dialog, Divider, IconButton, Slide, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { ITraitSnapshot } from 'src/models';
import { useTheme, styled, Theme } from '@mui/material/styles';
import Image from 'src/components/image';
import Carousel from 'src/components/carousel';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Commentary from 'src/screens/commentary/Commentary';
import Detail from '../detail/Detail';
import { CallApiToStore } from 'src/utils/common';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
interface Props {
  handleClose: VoidFunction
}

export const Analysis = observer(({
  handleClose
}: Props) => {

  const rootStore = useStores();
  const { reportStore, loadingStore } = rootStore;

  const theme = useTheme();

  const res = reportStore.report;
  // const [guideName, setGuideName] = useState(res.productName.split(' ')[0].toLowerCase());
  const guideName = res.productName.split(' ')[0].toLowerCase();

  const { REACT_APP_IMAGE_STORAGE } = process.env;

  useEffect(() => {
    if (guideName) {
      sendReactNativeMessage({
        type: 'topBackgroundChange',
        payload: {
          color: bgColor[guideName]
        }
      })
    }
    return () => {
      // setGuideName('');
    }
  }, [guideName])

  const contentRef = useRef<any>(null);
  const carouselRef = useRef<Carousel | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselSettings = {
    dots: false,
    arrows: false,
    autoplay: false,
    draggable: false,
    slidesToShow: 1,
    // slidesToScroll: 1,
    // initialSlide: 1,
    rtl: false,
    speed: 200,
    infinite: false,
    easing: 'easeOut',
    centerMode: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
    contentRef.current?.scrollIntoView();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    contentRef.current?.scrollIntoView();
  };

  const bgColor: any = {
    master: '#e6d6ff',
    grand: '#fff9a8',
    standard: '#eaecef'
  }

  const bgUrl: any = {
    master: {
      background: `url(${REACT_APP_IMAGE_STORAGE}zoom_mobile_page/imgMaster.svg) 100% 100% no-repeat rgb(230, 214, 255)`
    },
    grand: {
      background: `url(${REACT_APP_IMAGE_STORAGE}zoom_mobile_page/imgGrand.png) 100% 100% no-repeat rgb(255, 249, 168)`
    },
    standard: {
      background: `url(${REACT_APP_IMAGE_STORAGE}zoom_mobile_page/imgStandard.png) 100% 100% no-repeat rgb(234, 236, 239)`
    },
  }

  const [openCommentary, setOpenCommentary] = useState(false);
  const closeCommentary = () => {
    setOpenCommentary(false);
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: bgColor[guideName]
      }
    })
  }

  const [openDetailCode, setDetailCode] = useState('');
  const [openDetail, setOpenDetail] = useState(false);
  const closeDetail = () => {
    setOpenDetail(false);
    sendReactNativeMessage({
      type: 'topBackgroundChange',
      payload: {
        color: bgColor[guideName]
      }
    })
  }

  const viewDetail = (code: string) => {


    CallApiToStore(reportStore.getFavorite(Number(res.orderId)), 'api', loadingStore).then(() => {

      sendReactNativeMessage({
        type: 'topBackgroundChange',
        payload: {
          color: '#FFFFFF'
        }
      })
      setDetailCode(code)
      setOpenDetail(true)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (res.cardios.length > 0) {
        viewDetail(res.cardios[1].code);
      }

      // if (res.cancers.length > 0) {
      //   viewDetail(res.cancers[0].code);
      // }
    }, 1000)
  }, [])

  return (
    <>
      <Box>
        <Stack ref={contentRef} direction={'row'} sx={{
          background: bgColor[guideName],
          justifyContent: 'space-between',
          justifyItems: 'cemter',
          alignContent: 'center',
          p: 2
        }}>
          <IconButton sx={{}} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
          <Typography variant={'h5'} sx={{ pt: .8 }}>{res.productName}</Typography>
          <IconButton sx={{}} onClick={() => {
            setOpenCommentary(true);
            sendReactNativeMessage({
              type: 'topBackgroundChange',
              payload: {
                color: '#FFFFFF'
              }
            })
          }}><InfoOutlinedIcon /></IconButton>
        </Stack>

        <Box sx={{ ...bgUrl[guideName], minHeight: 220, p: 2, pt: 1, borderBottomLeftRadius: 24, borderBottomRightRadius: 24, }}>
          <Stack direction={'row'} spacing={1} sx={{ mb: 3 }}>
            {
              res.productCode.includes('GTSTRD_G01') ?
                <Chip label={'심뇌혈관질환'} variant={'outlined'} size={'medium'} style={{ ...chipStyle, ...(currentIndex === 0 ? chipStyleActive : '') }} className={'active'} />
                :
                res.productCode === 'GTSTRD_M01' ?
                  < Chip label={'남성 주요 암'} variant={'outlined'} size={'medium'} style={{ ...chipStyle, ...(currentIndex === 0 ? chipStyleActive : '') }} />
                  :
                  res.productCode === 'GTSTRD_F01' ?
                    <Chip label={'여성 주요 암'} variant={'outlined'} size={'medium'} style={{ ...chipStyle, ...(currentIndex === 0 ? chipStyleActive : '') }} />
                    :
                    <>
                      <Chip label={'암 및 주요질환'} variant={'outlined'} size={'medium'} style={{ ...chipStyle, ...(currentIndex === 0 ? chipStyleActive : '') }} className={'active'} onClick={handlePrev} />
                      <Chip label={'심뇌혈관질환'} variant={'outlined'} size={'medium'} style={{ ...chipStyle, ...(currentIndex === 1 ? chipStyleActive : '') }} onClick={handleNext} />
                    </>
            }
          </Stack>

          <Box sx={{ p: 1 }}>
            <Typography variant='body2'>종합결과</Typography>
            {
              currentIndex < 1 ?
                <Box sx={{ maxWidth: '50%', mt: 1 }}>
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>{res.overviewShort?.cancer}</Typography>
                </Box>
                :
                <Box sx={{ maxWidth: '50%', mt: 1 }}>
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>{res.overviewShort?.cardio}</Typography>
                </Box>
            }
            orderId : {res.orderId}
          </Box>

        </Box>

        <Carousel ref={carouselRef} {...carouselSettings}>
          <Stack sx={{ p: 2, pt: 1.2 }} spacing={1.2}>
            {
              res.cancers.map((item: ITraitSnapshot, i: number) => {
                return (
                  <ReportCard key={'report-card-cancers-' + i}>
                    <Stack direction={'row'} onClick={() => {
                      viewDetail(item.code)
                      {/* https://gentok.com/api/analysis/zoom/search?page_type=detail&order_id=5349797414305792&trait_code=CHD_00009 */ }
                    }}
                      sx={{
                        justifyContent: 'space-between'
                      }}>
                      <Image src={REACT_APP_IMAGE_STORAGE + `zoom_mobile_page/${item.code}.svg`} disabledEffect sx={{ maxWidth: { xs: 60, lg: 80 } }} />
                      <Box sx={{ flex: 1, ml: 2.5 }}>
                        <Typography variant='body1' sx={{ fontSize: '1rem', mb: 1 }}>{item.korean}</Typography>
                        <Divider sx={{ borderColor: '#b4b8c5', borderWidth: .5, mb: 1 }} />
                        <Stack direction={'row'} spacing={2}>
                          {
                            res.productCode.includes('GTMSTR') &&
                            (
                              <Box>
                                <Typography sx={{ fontSize: '.7rem', color: theme.palette.text.secondary }}>병원성 변이</Typography>
                                {
                                  item.pathogenic.score > 0 ?
                                    <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/icoTarget.svg) no-repeat', pl: 3, color: '#333333' }}>있음</Box>
                                    :
                                    <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/1906.svg) no-repeat', pl: 3, color: '#AAAAAA' }}>없음</Box>
                                }
                              </Box>
                            )
                          }
                          <Box>
                            <Typography sx={{ fontSize: '.7rem', color: theme.palette.text.secondary }}>연관성 변이</Typography>
                            {
                              item.gwas.grade === 3 ?
                                <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleGreen.svg) no-repeat 4px 50%', pl: 3 }}>양호</Box>
                                :
                                item.gwas.grade === 2 ?
                                  <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleRed.svg) no-repeat 4px 50%', pl: 3 }}>관심</Box>
                                  :
                                  <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleYellow.svg) no-repeat 4px 50%', pl: 3 }}>주의</Box>
                            }
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: '.7rem', color: theme.palette.text.secondary }}>환경적 변이</Typography>
                            {
                              item.emrAndSurvey.summary > 0 ?
                                <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/icoCheck.svg) no-repeat 4px 50%', pl: 3 }}>있음</Box>
                                :
                                <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/icoCheckOff.svg) no-repeat 4px 50%', pl: 3, color: '#AAAAAA' }}>없음</Box>
                            }
                          </Box>
                        </Stack>
                      </Box>
                      <IconButton sx={{}} onClick={() => { }}><ArrowRightIcon sx={{ color: '#CCCCCC' }} /></IconButton>
                    </Stack>
                  </ReportCard>
                )
              })
            }
          </Stack>


          <Stack sx={{ p: 2, pt: 1.2 }} spacing={1.2}>
            <Button sx={{ border: '1px solid #e6e8ed', borderRadius: 4, p: 1.3, color: '#333333', fontSize: '1rem', fontWeight: 200, width: '100%' }} onClick={() => {

            }}>건강위험도 보기</Button>
            {
              //<button class="disease-btn"  onclick="location.href = 'search?page_type=caution&order_id={{ data.report.order_id }}'">건강위험도 보기</button>
              res.cardios.map((item: ITraitSnapshot, i: number) => {

                return (
                  <ReportCard key={'report-card-cardios-' + i}>
                    <Stack direction={'row'} onClick={() => {
                      viewDetail(item.code)
                      // onclick = "location.href = 'search?page_type=detail&order_id={{ data.report.order_id }}&trait_code={{ trait_items.code }}'" style = "cursor:pointer;"
                    }}
                      sx={{
                        justifyContent: 'space-between'
                      }}>
                      <Image src={REACT_APP_IMAGE_STORAGE + `zoom_mobile_page/${item.code}.svg`} disabledEffect />
                      <Box sx={{ flex: 1, ml: 2.5 }}>
                        <Typography variant='body1' sx={{ fontSize: '1rem', mb: 1 }}>{item.korean}</Typography>
                        <Divider sx={{ borderColor: '#b4b8c5', borderWidth: .5, mb: 1 }} />
                        <Stack direction={'row'} spacing={2}>
                          {
                            res.productCode.includes('GTMSTR') &&
                            (
                              <Box>
                                <Typography sx={{ fontSize: '.7rem', color: theme.palette.text.secondary }}>병원성 변이</Typography>
                                {
                                  item.pathogenic.score > 0 ?
                                    <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/icoTarget.svg) no-repeat', pl: 3, color: '#333333' }}>있음</Box>
                                    :
                                    <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/1906.svg) no-repeat', pl: 3, color: '#AAAAAA' }}>없음</Box>
                                }
                              </Box>
                            )
                          }
                          <Box>
                            <Typography sx={{ fontSize: '.7rem', color: theme.palette.text.secondary }}>연관성 변이</Typography>
                            {
                              item.gwas.grade === 3 ?
                                <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleGreen.svg) no-repeat 4px 50%', pl: 3 }}>양호</Box>
                                :
                                item.gwas.grade === 2 ?
                                  <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleRed.svg) no-repeat 4px 50%', pl: 3 }}>관심</Box>
                                  :
                                  <Box sx={{ background: 'url(' + REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgCircleYellow.svg) no-repeat 4px 50%', pl: 3 }}>주의</Box>
                            }
                          </Box>
                        </Stack>
                      </Box>
                      <IconButton sx={{}} onClick={() => { }}><ArrowRightIcon sx={{ color: '#CCCCCC' }} /></IconButton>
                    </Stack>
                  </ReportCard>
                )
              })
            }
          </Stack>
        </Carousel>
      </Box>

      <Dialog
        key={'reportCommentary'}
        fullWidth
        keepMounted
        maxWidth={'lg'}
        open={openCommentary}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            p: 0,
            m: 0,
            maxHeight: '100%',
            minHeight: '100%',
            borderRadius: '0 !important',
            '@media (max-width: 600px)': {
              margin: 0
            }
          }
        }}
        onClose={closeCommentary}
        sx={{ margin: '0 !important', zIndex: 9999999, padding: 0, borderRadius: 0 }}
      >
        {
          openCommentary &&
          <Commentary handleClose={closeCommentary} />
        }
      </Dialog>

      {
        openDetail &&
        <Dialog
          key={'reportDetail'}
          fullWidth
          keepMounted
          maxWidth={'lg'}
          open={openDetail}
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              p: 0,
              m: 0,
              maxHeight: '100%',
              minHeight: '100%',
              borderRadius: '0 !important',
              '@media (max-width: 600px)': {
                margin: 0
              }
            }
          }}
          onClose={closeDetail}
          sx={{ margin: '0 !important', zIndex: 9999999, padding: 0, borderRadius: 0 }}
        >
          {
            openDetail &&
            <Detail handleClose={closeDetail} code={openDetailCode} />
          }
        </Dialog>
      }
    </>
  );
});


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ReportCard = styled(Card)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(0),
  borderRadius: 16,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#e6e8ed',
  marginbottom: 2,
  boxShadow: 'none'
}));

const chipStyle = {
  padding: 8, height: 40, borderRadius: 20, background: '#c8bedf', borderColor: '#BBBBBB', color: '#FFFFFF', fontSize: '1rem',
}
const chipStyleActive = {
  background: '#FFFFFF',
  color: '#333333'
}

export default Analysis;
