import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # ReportOverview
 *
 * ReportOverview을 설명하세요.
 */
export const ReportOverview = types
  .model("ReportOverview")
  // --------------------------------------------------------------------------
  .props({
    cardio: types.optional(types.string, ''),
    cancer: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model reportOverview --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IReportOverview;
      (Object.keys(newProps) as TReportOverviewKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TReportOverview = Instance<typeof ReportOverview>;
type TReportOverviewSnapshot = SnapshotOut<typeof ReportOverview>

export interface IReportOverview extends TReportOverview { }
export type TReportOverviewKeys = keyof TReportOverviewSnapshot & string;
export interface IReportOverviewSnapshot extends TReportOverviewSnapshot { }
export const createReportOverview = () =>
  types.optional(ReportOverview, {
  } as TReportOverview);
