import { useTheme } from '@emotion/react';
import { Masonry } from '@mui/lab';
import { Card, CardActionArea, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { useStores } from "../../../models/root-store/root-store-context"
import { SPACING } from 'src/config-global';
import Iconify from '../../../components/iconify/Iconify';
/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */

type Props = {
  data: any[];
}
export const Bookmark = observer((
  { data }: Props
) => {

  const rootStore = useStores();
  const { userStore, loadingStore } = rootStore;

  const theme = useTheme();

  const isLg = useResponsive('up', 'lg');

  const { REACT_APP_API_URL } = process.env;

  const [list, setList] = useState<{ id: number, title: string, subtitle: string, link: string }[]>([]);

  const getData = async () => {
    let response = await fetch(REACT_APP_API_URL + 'favorite', {
      method: 'GET',
      headers: {
        'User-Id': userStore.user.userId || '',
        'Token': userStore.user.userToken || '',
        // 'credentials': 'true',
        // mode: 'no-cors'
      },
    }).then(async (r: any) => {
      const res: any = await r.json();
      console.log('🌈 ~ getData ~ res', res)
      setList(res.favoriteCardnewsList);
      // console.log('r', res)
    }).catch((e) => {
    })
  }

  useEffect(() => {

    getData();

  }, []);

  const onClick = (id: string, list: string[]) => {
  }

  return (
    <>
      {
        list.length > 0 ?
          <Masonry columns={{ xs: 2, md: 2, lg: 2 }} spacing={isLg ? 3 : 1.5} sx={{ p: 0, m: 0 }}>
            {
              list.map((item: any, i: number) => {
                return (
                  <BookmarkItem key={'bookmark-' + item.id} item={item} />
                )
              })
            }
          </Masonry>
          :
          <Stack spacing={2} justifyContent={'center'} sx={{ pt: 4 }}>
            <Iconify icon={'ic:outline-bookmark-border'} sx={{ width: 40, height: 70, alignSelf: 'center', color: '#aaaaaa' }} />
            <Typography sx={{ fontSize: '1.2rem', mb: 1.5 }}>등록된 즐겨찾기가 없습니다.</Typography>
            <Typography sx={{ fontSize: '.8rem' }}>카드 뉴스에서 우측 상단의 ♡모양을 클릭하면<br />즐겨찾기가 가능합니다.</Typography>
          </Stack>
      }
    </>
  );
});

export default Bookmark;

type BookmarkItemProps = {
  id: string;
  title: string;
  subtitle: string;
  link: string;
};

function BookmarkItem({ item }: { item: BookmarkItemProps }) {

  return (
    <Card
      sx={{
        pb: 0,
        border: '1px solid #e3e3e3',
      }} elevation={4}
      onClick={() => {
        alert('move ' + item.link)
      }}>
      <CardContent sx={{ minHeight: 400, p: 0, pt: 3, pb: 0, textAlign: 'center' }}>
        <img src='/assets/images/bookmark/logo-top.png' alt={'GenTok'} style={{ maxWidth: '40%', height: 'auto', margin: 'auto' }} />
        <Box sx={{ background: '#dc4551', p: 1, mt: 1.2, mb: 1, textAlign: 'center' }}>
          <Typography variant={'body1'} sx={{ fontSize: '1.25rem', fontWeight: 600, color: '#FFFFFF' }}>{item.title}</Typography>
        </Box>
        <img src='/assets/images/bookmark/logo-middle.png' alt={'GenTok'} style={{ maxWidth: '40%', height: 'auto', margin: 'auto' }} />
        <Box sx={{ p: 2, pb: 0, mt: 3, borderTop: '1px solid #e3e3e3' }}>
          <Typography variant={'body1'} sx={{}}>{item.subtitle}</Typography>
        </Box>
      </CardContent>
    </Card >
  );
}