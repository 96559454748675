import { Button, Card, Chip, Dialog, DialogContent, DialogTitle, Divider, IconButton, List, styled, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import { ICardioEnvItem, IGenotypesSnapshot, ITraitSnapshot } from 'src/models';

import Image from 'src/components/image';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { toJS } from 'mobx';
import Iconify from 'src/components/iconify';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'src/components/carousel';
import { Masonry } from '@mui/lab';
import { alpha } from '@mui/material/styles';

/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
interface Props {
  handleClose: VoidFunction;
  code: string;
}
export const Detail = observer(({
  handleClose,
  code
}: Props) => {

  const rootStore = useStores();
  const { reportStore, userStore, loadingStore } = rootStore;
  const report = reportStore.report;
  const { REACT_APP_IMAGE_STORAGE, REACT_APP_API_ROOT, REACT_APP_IMAGE_REPORT } = process.env;
  const theme = useTheme();

  // const cardios: ITraitSnapshot;
  let item: ITraitSnapshot;
  item = report.cancers.filter((cancer: ITraitSnapshot) => {
    return cancer.code === code
  })[0];
  if (!item) {
    item = report.cardios.filter((cardio: ITraitSnapshot) => {
      return cardio.code === code
    })[0];
  }

  const svrtColor = item.gwas.grade === 3 ? 'rgb(0, 194, 183)' : item.gwas.grade === 2 ? 'rgb(255, 179, 50)' : 'rgb(255, 91, 108)'
  console.log('item', toJS(item))

  const point = Number(item.gwas.avg.toFixed(2));
  const pointOver = (((item.gwas.score / item.gwas.avg) * 100) - 100).toFixed(2);
  const pointUnder = (100 - ((item.gwas.score / item.gwas.avg) * 100)).toFixed(2);

  const genes = reportStore.metaGene;
  console.log('🌈 ~ genes', toJS(genes))
  const traits = reportStore.metaTrait;
  console.log('🌈 ~ traits', toJS(traits))
  const envs = reportStore.metaEnv;
  console.log('🌈 ~ envs', toJS(envs))

  const contentRef = useRef<any>(null);
  const carouselRef = useRef<Carousel | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselSettings = {
    dots: false,
    arrows: false,
    autoplay: false,
    draggable: false,
    slidesToShow: 1,
    rtl: false,
    speed: 200,
    infinite: false,
    easing: 'easeOut',
    centerMode: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
    contentRef.current?.scrollIntoView();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    contentRef.current?.scrollIntoView();
  };

  const content = useRef<any>();

  const [info, setInfo] = useState({
    pop1: false, pop11: false, pop2: false, pop3: false
  });

  useEffect(() => {
    // setTimeout(() => {
    //   content.current?.scrollIntoView({
    //     block: 'end',
    //     // inline: 'center',
    //     behavior: 'smooth'
    //   })

    // }, 500)
    console.log('info', info)
  }, [info])


  return (
    <>
      <Stack sx={{ p: 2 }} ref={content}>
        <Stack direction={'row'} sx={{
          justifyContent: 'center',
          justifyItems: 'cemter',
          alignContent: 'center',
        }}>
          <IconButton sx={{ position: 'absolute', top: 20, left: 16 }} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
          <Typography variant={'h5'} sx={{ pt: .8 }}>{item.korean}</Typography>
        </Stack>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <ReportCard>
              <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: svrtColor, borderRadius: 2, width: 80, height: 80 }}>
                  <Box component={'img'} src={`${REACT_APP_IMAGE_STORAGE}zoom_mobile_page/${item.code}.svg`} sx={{ filter: 'brightness(3000%)' }} />
                </Box>
                {/* <img src={`${REACT_APP_IMAGE_STORAGE}zoom_mobile_page/${item.code}.svg`} style={{ color: '#FFFFFF', background: svrtColor, borderRadius: 2, width: 80, height: 80 }} /> */}
                <Box sx={{ flex: 1, ml: 2.5 }}>
                  <Typography variant='body2' sx={{ fontSize: '1rem', mb: 1 }}>{item.traitDesc}</Typography>
                </Box>
              </Stack>
              <Stack spacing={2} direction={'row'} sx={{ border: '1px solid #a3b8dd', borderRadius: 2, p: 2 }}>
                <Typography sx={{ minWidth: 80 }}>관리팁</Typography>

              </Stack>
            </ReportCard>
          </Stack>

          <Stack direction={'row'} sx={{ borderRadius: 4, padding: '2px', background: '#f5f5f5' }}>
            {

              !reportStore.report.productCode.includes('GTSTRD_G01') &&
              <Buttons size={'medium'} className={currentIndex !== 0 ? 'disabled' : ''} label={'연관성 변이'} onClick={() => carouselRef.current?.slickGoTo(0)} />
            }
            {
              (!reportStore.report.productCode.includes('GTSTRD_G01') && reportStore.report.productCode.includes('GTMSTR')) ?
                reportStore.report.cancers.length > 0 ?
                  <>
                    <Buttons size={'medium'} className={currentIndex !== 1 ? 'disabled' : ''} label={'병원성 변이'} onClick={() => carouselRef.current?.slickGoTo(1)} />
                    <Buttons size={'medium'} className={currentIndex !== 2 ? 'disabled' : ''} label={'환경적 변이'} onClick={() => carouselRef.current?.slickGoTo(2)} />
                  </>
                  :
                  <Buttons size={'medium'} className={currentIndex !== 1 ? 'disabled' : ''} label={'병원성 변이'} onClick={() => carouselRef.current?.slickGoTo(1)} />
                :
                <Buttons size={'medium'} className={currentIndex !== 2 ? 'disabled' : ''} label={'환경적 변이'} onClick={() => carouselRef.current?.slickGoTo(1)} />
            }
          </Stack>

        </Stack>

      </Stack>

      <Carousel ref={carouselRef} {...carouselSettings}>

        <Stack key={'carousel-1'} spacing={2} sx={{ p: 2 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='h5'>유전적 위험도</Typography>
            <Typography onClick={() => setInfo({ ...info, pop1: true })} variant={'body2'} color={theme.palette.text.disabled} sx={{ display: 'flex', alignItems: 'center' }}><Iconify icon={'ic:baseline-error-outline'} width={16} height={16} sx={{ mr: .4, color: theme.palette.text.disabled }} /> 유전적 위험도</Typography>
          </Stack>
          <Box component={'img'} src={REACT_APP_API_ROOT + item.traitGraph} sx={{ width: '100%' }} />

          <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
            <Box sx={{ minWidth: '35%', borderRadius: 2, background: svrtColor, textAlign: 'center', p: 2 }}>
              <Typography variant='h4' sx={{ fontSize: '1.8rem', color: '#FFFFFF', m: 1.5 }}>{Number(item.gwas.avg).toFixed(2)}<span style={{ fontSize: '1rem' }}>배</span></Typography>
              <Button sx={{ width: '100%', borderRadius: 4, background: '#FFFFFF', color: '#333333' }}>위험도 보기</Button>
            </Box>
            <ReportCard sx={{ fontWeight: 600 }}>
              {
                point > 1.00 ? `
                        수검자의 ${item.korean}에 대한 유전적 위험 수치는 한국인 대비
                        ${point}배로, 이는 ${pointOver}% 증가되어 있음을 의미합니다.`
                  : point < 1.00 ? `
                        수검자의 ${item.korean}에 대한 유전적 위험 수치는 한국인 대비
                        ${point}배로, 이는 ${pointUnder}% 증가되어 있음을 의미합니다.`
                    : `수검자의 ${item.korean}에 대한 유전적 위험 수치는 한국인 평균과 동일함을 의미합니다.`
              }
            </ReportCard>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='h6'>{userStore.user.userName || '수검자'}님의 유전형</Typography>
            <Typography onClick={() => setInfo({ ...info, pop11: true })} variant='body2' color={theme.palette.text.disabled} sx={{ display: 'flex', alignItems: 'center' }}><Iconify icon={'ic:baseline-error-outline'} width={16} height={16} sx={{ mr: .4, color: theme.palette.text.disabled }} /> 유전형</Typography>
          </Stack>

          {
            item.gwas.genotypes.map((gene: IGenotypesSnapshot, i: number) => {

              const tit = gene.sample.replace('/', '') === gene.allele;
              const yn = gene.sample === gene.allele + '/' + gene.allele ? 'YY' : gene.sample === gene.allele + '/' + gene.otherAllele ? 'YN' : 'NN';

              const bg = REACT_APP_IMAGE_REPORT + 'gene/associate_gene/2.0.0/' + gene.gene + '_' + yn + '.png'

              const meta = reportStore.metaGene.filter((g) => g.geneName === gene.gene)[0];

              return (
                <ReportCard key={`geno-${i}`}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant={'subtitle1'} sx={{ width: 100, textAlign: 'center', mb: 1, fontSize: '1rem' }}>{gene.gene}</Typography>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Box sx={{ position: 'relative', background: `url(${bg}) 50% 0 no-repeat`, backgroundSize: 'auto 75%', width: 100, height: 110, minWidth: 100, textAlign: 'center', mb: 2 }}>
                        <Typography variant={'body2'} sx={{ position: 'absolute', bottom: 0, width: 100, textAlign: 'center' }}>{meta?.location}</Typography>
                      </Box>
                      <Stack direction={'row'} alignItems={'center'}>
                        {
                          gene.sample.replace('/', '') === gene.allele + '' + gene.allele ?
                            <>
                              <Chips size={'medium'} label={(<span style={{ color: 'red' }}>{gene.allele}{gene.allele}</span>)} sx={{ border: '1px solid #000', background: '#FFFFFF' }} />
                              <Chips size={'medium'} label={gene.allele + '' + gene.allele} className={'disabled'} />
                              <Chips size={'medium'} label={gene.otherAllele + '' + gene.otherAllele} className={'disabled'} />
                            </>
                            : gene.sample.replace('/', '') === gene.otherAllele + '' + gene.otherAllele ?
                              <>
                                <Chips size={'medium'} label={gene.allele + '' + gene.allele} className={'disabled'} />
                                <Chips size={'medium'} label={gene.allele + '' + gene.otherAllele} className={'disabled'} />
                                <Chips size={'medium'} label={gene.otherAllele + '' + gene.otherAllele} sx={{ border: '1px solid #000', background: '#FFFFFF' }} />
                              </>
                              :
                              <>
                                <Chips size={'medium'} label={gene.allele + '' + gene.allele} className={'disabled'} />
                                <Chips size={'medium'} label={(<><span style={{ color: 'red' }}>{gene.allele}</span><span>{gene.otherAllele}</span></>)} className={'disabled'} />
                                <Chips size={'medium'} label={(<span style={{ color: 'red' }}>{gene.otherAllele + '' + gene.otherAllele}</span>)} className={'disabled'} />
                              </>
                        }
                      </Stack>
                    </Stack>
                    <Typography>{meta.descShort}</Typography>
                  </Box>
                </ReportCard>
              )
            })
          }
        </Stack>


        {
          report.productCode.includes('GTMSTR') &&
          <Stack key={'carousel-2'} spacing={2} sx={{ p: 2 }}>
            <Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant='h5'>병원성 변이</Typography>
                <Typography onClick={() => setInfo({ ...info, pop2: true })} variant='body2' color={theme.palette.text.disabled} sx={{ display: 'flex', alignItems: 'center' }}><Iconify icon={'ic:baseline-error-outline'} width={16} height={16} sx={{ mr: .4, color: theme.palette.text.disabled }} /> 병원성 변이</Typography>
              </Stack>

              {
                item.pathogenic.screenedVariants === 0 ?
                  <Typography>{item.korean}과 관련된<br />병원성 변이는<br />제공하지 않습니다.</Typography>
                  :
                  item.pathogenic.score > 0 ?
                    <>
                      <Typography>{item.korean}과 관련된<br />병원성 변이 {item.pathogenic.screenedVariants}개를 분석한 결과<br /><span style={{ color: 'red' }}>{item.pathogenic.score}개</span>가 발견되었습니다.</Typography>
                      <Typography>발견된 병원성 변이 : <strong style={{ color: 'red' }}>
                        {
                          item.pathogenic.positiveVariants.map(r => (r.gene))
                        }
                        {
                          item.pathogenic.score > 3 && `[외 ${item.pathogenic.positiveVariants.length}개 발견]`
                        }
                      </strong>
                      </Typography>
                    </>
                    :
                    <Typography>{item.korean}과 관련된<br />병원성 변이{item.pathogenic.screenedVariants}개를 분석한 결과<br />발견된 병원성 변이는 없습니다.</Typography>
              }

              <Box component={'img'} src={REACT_APP_API_ROOT + item.traitPathogenic} sx={{ mt: 2, width: '100%', border: '1px solid ' + theme.palette.divider, borderRadius: 2 }} />

            </Stack>

          </Stack>
        }

        {
          report.cancers.length > 0 &&
          <Stack key={'carousel-3'} spacing={2} sx={{ p: 2 }}>
            <Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant='h5'>환경적 요인</Typography>
                <Typography onClick={() => setInfo({ ...info, pop3: true })} variant='body2' color={theme.palette.text.disabled} sx={{ display: 'flex', alignItems: 'center' }}><Iconify icon={'ic:baseline-error-outline'} width={16} height={16} sx={{ mr: .4, color: theme.palette.text.disabled }} /> 환경적 요인</Typography>
              </Stack>

              {
                <>
                  <Typography variant={'body1'}>
                    {item.korean}에 대한<br />
                    환경적 위험 요소는<br />
                    {
                      report.cardioEnv && (
                        report.cardioEnv.envList.length === 0 ? '제공하지 않습니다'
                          :
                          item.score === 0 ? '발견되지 않았습니다'
                            :
                            <>
                              <strong>{report.cardioEnv.envList.length}개</strong> 중<br />
                              <span style={{ color: 'blue' }} >{item.pathogenic.score}개</span>가 발견되었습니다.
                            </>
                      )
                    }
                  </Typography>
                </>
              }

              <Masonry columns={2} spacing={2} sx={{ p: 0, m: 0 }}>
                {
                  report.cardioEnv ?
                    report.cardioEnv.envList.map((env: ICardioEnvItem, i: number) => {
                      return (
                        <Box key={'env-' + i} sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: 2, p: 4, textAlign: 'center', alignItems: 'center' }}
                          onClick={() => {

                          }}>
                          <Typography variant={'body1'}>{env.korean}</Typography>
                          <Typography variant={'body1'} sx={{
                            fontSize: '1.4rem',
                            color: alpha(theme.palette.text.primary, .2)
                          }} >없음</Typography>
                        </Box>
                      )
                    })
                    : ''
                }
              </Masonry>

            </Stack>

          </Stack>
        }


      </Carousel>

      {
        info.pop1 &&
        <Dialog
          key={'infoPop'}
          open={info.pop1}
          keepMounted
          maxWidth={'md'}
          onClose={() => setInfo({ ...info, pop1: false })}
          sx={{ borderRadius: 4, zIndex: 9999999 }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            유전적 위험도
            <IconButton onClick={() => setInfo({ ...info, pop1: false })} sx={{ position: 'absolute', top: 16, right: 16 }}>
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 4 }}>
            <Box component={'img'} src={REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/danger.jpg'} sx={{ mb: 2, height: '100%' }} />
            <Stack spacing={2}>
              <Typography variant='body2'>
                유전적 위험도는 한국인 데이터베이스를 바탕으로 해당 질병의 한국인 평균 위험도와 나의 유전적 위험도를 비교한 상대적 위험도입니다.
              </Typography>
              <Chip label={'X축'} color={'default'} sx={{ width: 50 }} />
              <Typography variant='body2'>
                질병 별 위험수치로 최소값과 최대값이 달라질 수 있습니다.
              </Typography>
              <Chip label={'Y축'} color={'default'} sx={{ width: 50 }} />
              <Typography variant='body2'>
                한국인 데이터베이스를 바탕으로 계산된 인구비율입니다.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      }

      {
        info.pop11 &&
        <Dialog
          key={'infoPop'}
          open={info.pop11}
          maxWidth={'md'}
          onClose={() => setInfo({ ...info, pop11: false })}
          sx={{ borderRadius: 4, zIndex: 9999999 }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            유전형
            <IconButton onClick={() => setInfo({ ...info, pop11: false })} sx={{ position: 'absolute', top: 16, right: 16 }}>
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 4, textAlign: 'center' }}>
            <Stack justifyContent={'center'} sx={{ m: 2 }}>
              <Typography variant='subtitle1'>DIRC3</Typography>
              <Box component={'img'} src={REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgGenotypeRedblack.svg'} sx={{ m: 2, height: 100 }} />
              <Typography variant='body2' sx={{ color: theme.palette.text.disabled }}>2q35</Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography variant='body2'>
                해당 질병의 유전적 위험도를 측정하기 위해 분석한 유전자 이름과 염색체 내 위치를 나타냅니다.
              </Typography>
              <Stack direction={'row'} justifyContent={'space-around'} sx={{ p: 2 }}>
                <Chips size={'medium'} label={'AA'} className={'disabled'} />
                <Chips size={'medium'} label={(<><span style={{ color: 'black' }}>A</span>G</>)} sx={{ border: '1px solid #000', background: '#FFFFFF' }} />
                <Chips size={'medium'} label={'AG'} className={'disabled'} />
              </Stack>
              <Typography variant='body2'>
                해당 유전자에서 분석된 당신의 유전형을 표시합니다. 붉은색으로 표시된 유전인자는 질병 발생 위험도를 높일 수 있습니다.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      }

      {
        info.pop2 &&
        <Dialog
          key={'infoPop'}
          open={info.pop2}
          keepMounted
          maxWidth={'md'}
          onClose={() => setInfo({ ...info, pop2: false })}
          sx={{ borderRadius: 4, zIndex: 9999999 }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            병원성 변이
            <IconButton onClick={() => setInfo({ ...info, pop2: false })} sx={{ position: 'absolute', top: 16, right: 16 }}>
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 4, textAlign: 'center' }}>
            <Stack justifyContent={'center'} sx={{ m: 2 }}>
              <Box component={'img'} src={REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgModalVariant.svg'} sx={{ m: 2, height: 120 }} />
            </Stack>
            <Stack spacing={2}>
              <Typography variant='body2'>
                질병 발생 가능성을 높일 것으로 예측되는 강력한 유전변이로, 발견 시 의료진 상담이 필요합니다.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      }

      {
        info.pop3 &&
        <Dialog
          key={'infoPop'}
          open={info.pop3}
          maxWidth={'md'}
          onClose={() => setInfo({ ...info, pop3: false })}
          sx={{ borderRadius: 4, zIndex: 9999999 }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            환경적 요인
            <IconButton onClick={() => setInfo({ ...info, pop3: false })} sx={{ position: 'absolute', top: 16, right: 16 }}>
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 4, textAlign: 'center' }}>
            <Stack justifyContent={'center'} sx={{ m: 2 }}>
              <Box component={'img'} src={REACT_APP_IMAGE_STORAGE + 'zoom_mobile_page/imgEnvironmentalRisk.svg'} sx={{ m: 2, height: 120 }} />
            </Stack>
            <Stack spacing={2}>
              <Typography variant='body2' sx={{ textAlign: 'left' }}>
                환경적 요인은 질병 발생의 여러 요인 중 유전적 요인을 제외한 식이, 운동, 생활환경, 가족력 등을 말합니다. <br /><br />
                당신의 병원기록(EMR) 및 설문 결과를 바탕으로 발견된 환경적 요인을 표시합니다.<br /><br />
                질병 별로 표시되는 환경적 요인은 다르며, 당신의 환경적 위험 요인의 보유 유무에 따라 '있음' 또는 '없음'으로 표시됩니다.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      }
    </>
  );
});


export const Buttons = styled(Chip)(({ theme }) => ({
  flex: 1,
  color: '#000000',
  backgroundColor: '#FFFFFF',
  borderRadius: 16,
  fontWeight: 600,
  '&.disabled': {
    color: '#999',
    backgroundColor: '#f5f5f5',
  }
}));

export const Chips = styled(Chip)(({ theme }) => ({
  paddingLeft: 8,
  paddingRight: 8,
  marginRight: 4,
  fontWeight: 600,
  '&.disabled': {
    background: '#f9f9f9',
    border: '1px solid #dddddd'
  }
}));

const ReportCard = styled(Card)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: 16,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#e6e8ed',
  marginbottom: 2,
  boxShadow: 'none'
}));

export default Detail;
