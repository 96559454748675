import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../models/root-store/root-store-context"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';
/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
type Props = {
  handleClose: VoidFunction;
}

const faqList = [
  {
    "answer": "\uace0\uac1d\ub2d8\uc758 \uc720\uc804\uc790\ubd84\uc11d \uacb0\uacfc\ub97c \uc5b8\uc81c \uc5b4\ub514\uc11c\ub4e0 \ubcf4\ub2e4 \uc27d\uac8c \ud655\uc778\ud558\uc5ec \uc0dd\ud65c \uc18d\uc5d0\uc11c\uc758 \uac74\uac15\uad00\ub9ac\ub97c \ub3c4\uc640\ub4dc\ub9b4 \uc218 \uc788\ub294 \uc11c\ube44\uc2a4\uc785\ub2c8\ub2e4. \uc720\uc804\uc790\uac80\uc0ac\ub97c \ud1b5\ud574 \ucde8\uc57d\ud55c \uc9c8\ud658\uc744 \ud655\uc778 \ud6c4 \ub354\uc6b1 \uc138\uc2ec\ud55c \ub9de\ucda4 \uac74\uac15\uad00\ub9ac\ub97c \ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4.",
    "id": 1,
    "question": "\uc820\ud1a1\uc740 \uc5b4\ub5a4 \uc11c\ube44\uc2a4\uc778\uac00\uc694?"
  }, {
    "answer": "'\ud648->QR\ucf54\ub4dc \uc2a4\uce94'\uc744 \ud1b5\ud574 \ub4f1\ub85d\ud558\uc2e4 \uc218 \uc788\uc2b5\ub2c8\ub2e4. QR\ucf54\ub4dc\ub294 \uc720\uc804\uc790\uac80\uc0ac \ud6c4 \uc81c\uacf5\ub418\ub294 \uacb0\uacfc\uc9c0 \uccab \ud398\uc774\uc9c0\uc758 \uc6b0\uce21 \uc0c1\ub2e8\uc5d0 \uc704\uce58\ud558\uace0 \uc788\uc2b5\ub2c8\ub2e4.",
    "id": 2,
    "question": "\uc720\uc804\uc790 \ub9ac\ud3ec\ud2b8\ub294 \uc5b4\ub5bb\uac8c \ub4f1\ub85d\ud558\ub098\uc694?"
  }, {
    "answer": "'\ubcd1\uc6d0 \uad6c\ub9e4 \uc0c1\ud488'\uc758 \uacbd\uc6b0 \uc720\uc804\uc790\uac80\uc0ac \uc0c1\ud488\uc744 \uc11c\ube44\uc2a4\ud558\ub294 \uc758\ub8cc \uae30\uad00\uc5d0 \ubb38\uc758\ud558\uc2e0 \ud6c4 \uc9c1\uc811 \ubc29\ubb38\uc744 \ud1b5\ud574 \uad6c\ub9e4\ud560 \uc218 \uc788\uc73c\uba70, '\uc9c1\uc811 \uad6c\ub9e4 \uc0c1\ud488'\uc758 \uacbd\uc6b0 \uc820\ud1a1 APP\uc758 '\ubc14\ub85c \uad6c\ub9e4\ud558\uae30' \ubc84\ud2bc\uc744 \ub20c\ub7ec \uad6c\ub9e4\ud560 \uc218 \uc788\uc2b5\ub2c8\ub2e4. ",
    "id": 3,
    "question": "\uc720\uc804\uc790 \uc0c1\ud488 \uad6c\ub9e4\ub294 \uc5b4\ub5bb\uac8c \ud558\ub098\uc694?"
  }, {
    "answer": "'\ub9c8\uc774\ud398\uc774\uc9c0 -> \ub0b4 \uc815\ubcf4 \uc218\uc815 -> \ud0c8\ud1f4\ud558\uae30'\ub97c \ud1b5\ud574 \uac00\ub2a5\ud569\ub2c8\ub2e4. \uc11c\ube44\uc2a4 \ud0c8\ud1f4 \ud6c4 \ub2e4\uc2dc \uc11c\ube44\uc2a4\ub97c \uc774\uc6a9\ud558\uc2dc\ub824\uba74 '\ubcf8\uc778 \uc778\uc99d', '\ub9ac\ud3ec\ud2b8 \ub4f1\ub85d' \ub4f1\uc758 \uc808\ucc28\ub97c \ub2e4\uc2dc \uac70\uccd0\uc57c\ud558\uae30 \ub54c\ubb38\uc5d0 \uc2e0\uc911\ud558\uac8c \uacb0\uc815\ud558\uc154\uc57c \ud569\ub2c8\ub2e4.",
    "id": 4,
    "question": "\uc11c\ube44\uc2a4 \ud0c8\ud1f4\ub294 \uc5b4\ub5bb\uac8c \ud558\ub098\uc694?"
  }
];

export const Faq = observer(({
  handleClose
}: Props) => {

  const theme = useTheme();

  const { REACT_APP_API_URL } = process.env;

  const cheerio = require('cheerio');

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<{ id: number, answer: string, question: string }[]>([]);

  const getData = async () => {

    const res = await fetch(REACT_APP_API_URL + 'faq')
      .then(async (res: any) => {
        const json = await res.json();
        setList(json.faqList);
        setLoading(false);
      }).catch((e: any) => {
        setList(faqList);
        setLoading(false);
      });
  };

  useEffect(() => {

    getData();

  }, []);

  return (
    <>
      <Box sx={{
        position: 'relative',
        background: '#FFFFFF',
        textAlign: 'center',
        borderBottom: '1px solid #e5e5e5',
        p: 2,
      }}>
        <IconButton sx={{ position: 'absolute', left: 8, top: 11 }} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
        <Typography variant={'h5'}>FAQ</Typography>
      </Box>
      {
        loading ?
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><CircularProgress sx={{ width: 70, height: 70 }} color={'info'} /></Box>
          :
          <Box sx={{ flex: 1, overflowX: 'hidden' }}>

            {
              list.map((faq: { id: number, question: string, answer: string }) => {
                return (
                  <Accordion key={'qna' + faq.id} sx={{
                    '&.MuiAccordion-root.Mui-expanded': {
                      mt: 0,
                    },
                    '&.MuiAccordion-root.Mui-expanded:before': {
                      opacity: 1
                    }
                  }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.action.disabled }} />}
                      id={`faq-id-${faq.id}`}
                      sx={{
                        p: 1.5, pl: 2, pr: 2, mt: 0, borderBottom: '1px solid #f5f5f5',
                        '&.Mui-expanded': {
                          minHeight: 40,
                          '& .MuiAccordionSummary-content.Mui-expanded': {
                            margin: 0,
                            mt: 1.5
                          }
                        },
                      }}
                    >
                      <Typography variant={'body2'}>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: '#f5f5f5', p: 2, m: 2, mt: 0 }}>
                      <Typography variant={'body2'} sx={{ borderRadius: '50%', background: '#FFFFFF', width: 20, height: 20, textAlign: 'center', mb: 1 }}>A</Typography>
                      <Typography variant={'body2'}>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </Box>
      }
    </>
  );
});


export default Faq;