import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { Genotypes } from "../genotypes/Genotypes";

/**
 * # Gwas
 *
 * Gwas을 설명하세요.
 */
export const Gwas = types
  .model("Gwas")
  // --------------------------------------------------------------------------
  .props({
    avg: types.optional(types.number, 0),
    chartX: types.optional(types.number, 0),
    chartY: types.optional(types.number, 0),
    finalScore: types.optional(types.number, 0),
    grade: types.optional(types.number, 0),
    rawScore: types.optional(types.number, 0),
    relativeRisk: types.optional(types.number, 0),
    score: types.optional(types.number, 0),
    genotypes: types.optional(types.array(Genotypes), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model gwas --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IGwas;
      (Object.keys(newProps) as TGwasKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TGwas = Instance<typeof Gwas>;
type TGwasSnapshot = SnapshotOut<typeof Gwas>

export interface IGwas extends TGwas { }
export type TGwasKeys = keyof TGwasSnapshot & string;
export interface IGwasSnapshot extends TGwasSnapshot { }
export const createGwas = () =>
  types.optional(Gwas, {
  } as TGwas);
