import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Tok
 *
 * Tok을 설명하세요.
 */
export const Tok = types
  .model("Tok")
  // --------------------------------------------------------------------------
  .props({
    id: types.string,
    name: types.string,
    optinalValue: types.optional(types.string, 'default'),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model tok --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ITok;
      // @ts-ignore
      (Object.keys(newProps) as TTokKeys[]).forEach((key) => {
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TTok = Instance<typeof Tok>;
type TTokSnapshot = SnapshotOut<typeof Tok>

export interface ITok extends TTok { }
export type TTokKeys = keyof TTokSnapshot & string;
export interface ITokSnapshot extends TTokSnapshot { }
export const createTok = () =>
  types.optional(Tok, {
    id: 'tok-id',
    name: 'tok-name',
  } as TTok);
