import { Button, Dialog, FormHelperText, IconButton, Input, Stack, TextField, Theme, Typography, Slide } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Image from '../../../components/image';
import { blue, grey } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import { uuidForGentok } from 'src/utils/uuidv4';
import { loadString } from 'src/utils/storage';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from 'src/components/CommonContext';


/**
 * ## 기능 설명
 *
 * 이 페이지에 구현된 기능을 설명하시오.
 *
 */
type Props = {
  handleClose: VoidFunction;
}
export const CheckPhone = observer(({
  handleClose
}: Props) => {

  const theme = useTheme();
  const rootStore = useStores();
  const { userStore, loadingStore } = rootStore;
  const navigate = useNavigate();
  const common = useCommonContext();

  const [certivicate, setCertivicate] = useState(false)
  const [uuid, setUuid] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [confirmNumber, setConfirmNumber] = useState<string>();

  const { REACT_APP_API_URL } = process.env;

  const [sended, setSended] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendingConfirm, setSendingConfirm] = useState(false);

  // 인증번호 재발송
  const remainTime = useRef<number>(180000);
  const [remain, setRemain] = useState<number>();

  const sendSms = async () => {

    const check = phone?.replace('+82', '').replace(/ /gi, '');
    const pattern = /^01([0|1])([0-9]{3,4})([0-9]{4})$/;
    if (!check || !pattern.test(check)) {
      alert('휴대폰 번호를 입력해주세요.1 ' + check); return;
    }

    const uuid = uuidForGentok();

    const body: any = {
      'os': userStore.os,
      'token': uuid,
      'phone': check,
      'order_id': userStore.order_id
    };

    const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

    setSending(true);
    await fetch(REACT_APP_API_URL + 'sendNumber', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }).then((res: any) => {
      // console.log(res)

      setSending(false);
      if (res.ok && res.status === 200) {

        setUuid(uuid);
        setSended(true);
        remainTime.current = 180000;
        setRemain(180000)
        startTimer();
        alert(phone + '에 인증번호 발송이 요청되었습니다.');
      } else {
        alert('잘못된 요청입니다.')
      }
    });

  }

  // let timer: any;
  const startTimer = () => {
    const timer = setInterval(() => {
      remainTime.current -= 1000;
      setRemain(remainTime.current)
      if (remainTime.current < 1) {
        clearInterval(timer);
        alert("시간이 초과 되었습니다");
        handleClose();
      } else if (sendingConfirm) {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }

  const reSend = () => {
    // clearInterval(timer);
    remainTime.current = 180000;
    sendSms();
  }

  const confirmSubmit = async () => {

    const check = phone?.replace('+82', '').replace(/ /gi, '');
    const body: any = {
      'os': userStore.os,
      'token': uuid,
      'phone': check,
      'order_id': userStore.order_id,
      'verify': confirmNumber
    };

    const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

    setSendingConfirm(true);
    await fetch(REACT_APP_API_URL + 'numberAuthConfirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }).then(async (res: any) => {

      setSendingConfirm(false);

      const txt = await res.text();
      const json = JSON.parse(txt);

      setPhone('');
      setConfirmNumber('');
      remainTime.current = 180000;
      setRemain(180000)

      if (res.ok) {
        // navigate('/agree', { replace: false });

        // /authSuccess fetch? regist?

        common.onReportRegistDirect({
          order_id: userStore.order_id,
          json: {
            uuid: json.token,
            confirm_token: json.token,
            phone_number: check,
            latest: true
          }
        });

      } else {
        alert('에러가 발생했습니다. 처음부터 다시 시도해 주세요.')
        handleClose();
      }
    });

  }

  const nextFunc = async () => {
    // const uuid = uuidForGentok();
    const verify = userStore.verify_info;
    if (verify && verify !== '') {
      const json = JSON.parse(verify);
      const uuid = json.uuid;
      const url = 'https://gentoka.appspot.com/webapp/verify/' + uuid + '/' + userStore.os;
      sendReactNativeMessage({
        type: 'verify',
        payload: {
          url: url
        }
      });
    }
  }

  return (
    <>
      <Stack sx={{
        flex: 1,
        height: '100%',
        justifyContent: 'space-between'
      }}>
        <Box>
          <Box sx={{
            width: '100%',
            position: 'relative',
            background: '#FFFFFF',
            textAlign: 'center',
            borderBottom: '1px solid #e5e5e5',
            p: 2,
          }}>
            <IconButton sx={{ position: 'absolute', left: 8, top: 11 }} onClick={handleClose}><ArrowBackIosIcon fontSize={'small'} /></IconButton>
            <Typography variant={'h5'}>인증</Typography>
          </Box>

          {
            !certivicate ?
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Box sx={{ margin: '10vh auto', textAlign: 'center' }}>
                    <Image alt="GenTok" src="/assets/images/check-phone-illust.png"
                      sx={{ width: '70%', margin: 'auto', paddingBottom: 4 }}
                    />
                    <Typography variant={'body1'} sx={{ fontSize: '1.4rem', color: blue[500] }}>본인 명의의 휴대폰<span style={{ color: grey[800] }}>을</span></Typography>
                    <Typography variant={'body1'} sx={{ fontSize: '1.4rem', color: grey[800] }}>사용하고 계신가요?</Typography>
                    <Typography variant={'body2'} sx={{ color: grey[600], mt: 2 }}>유전자 검사를 받을 때 입력했던 휴대폰 정보입니다.</Typography>
                  </Box>
                  <Box sx={{ p: 3, pb: 0 }}>
                    <Box sx={{ width: '100%', height: 50, m: 'auto', position: 'relative' }}>
                      <Button variant={'contained'} sx={{ position: 'absolute', zIndex: 2, background: blue[600], color: '#FFF', borderRadius: 4, width: '55%', height: 50, '&:hover': { background: blue[600] } }} onClick={nextFunc}>네</Button>
                      <Button variant={'contained'} sx={{ position: 'absolute', zIndex: 1, right: 0, background: grey[100], color: grey[600], borderRadius: 4, width: '55%', height: 50 }} onClick={() => {
                        setCertivicate(true);
                      }}>아니오</Button>
                    </Box>
                  </Box>
                </Box>
              </>
              :
              <Box sx={{ margin: '10vh auto', p: 3, pt: 0 }}>
                <Typography variant={'body1'} sx={{ fontSize: '1.4rem', color: blue[500], mt: 2 }}>번호확인<span style={{ color: grey[800] }}>을 진행합니다</span></Typography>
                <Stack direction={'row'} sx={{ mt: 3, alignContent: 'center', justifyItems: 'center' }}>
                  <MuiPhoneNumber
                    key={'tel'}
                    name={'tel'}
                    defaultCountry={'kr'}
                    onChange={(e) => setPhone(e.toString())}
                    placeholder={'휴대전화번호 입력'}
                    disableAreaCodes={true}
                    disableCountryCode={false}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    // value={''}
                    sx={{ mt: 1, flex: 1, }} />
                  {
                    sended ?
                      <Button variant={'outlined'} color={'secondary'} size={'small'} sx={{ pl: 2, pr: 2, borderRadius: 4, minWidth: 130, height: 40 }} onClick={reSend} disabled={sending}>재전송</Button>
                      :
                      <Button variant={'outlined'} color={'secondary'} size={'small'} sx={{ pl: 2, pr: 2, borderRadius: 4, minWidth: 130, height: 40 }} onClick={sendSms} disabled={sending}>인증번호 받기</Button>
                  }
                </Stack>
                {
                  sended &&
                  <Stack direction={'row'} sx={{ alignContent: 'center', justifyItems: 'center', pt: 2 }}>
                    <TextField
                      variant={'standard'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = e.target;
                        console.log('🌈 ~ value', value)
                        const onlyNumber = value.replace(/[^0-9]/g, '')
                        setConfirmNumber(onlyNumber)
                      }}
                      value={confirmNumber}
                      placeholder={'인증번호 4자리 입력'}
                      fullWidth
                      type={'number'}
                      sx={{ mt: 2, color: '#000', fontWeight: 800 }}
                      InputProps={{
                        endAdornment: <Typography sx={{ color: '#FF0000' }}>{moment(remain).format('mm:ss')}</Typography>,
                        // onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        //   const { value } = e.target;
                        //   console.log('🌈 ~ value', value)
                        //   const onlyNumber = value.replace(/[^0-9]/g, '')
                        //   setConfirmNumber(onlyNumber)
                        // }
                      }}
                    />
                    {/* <Typography sx={{ color: '#FF0000' }}>{moment(remainTime).format('mm:ss')}</Typography> */}
                  </Stack>
                }
              </Box>
          }
        </Box>
        {
          sended === true &&
          <Box sx={{ p: 3 }}>
            <Button size={'large'} sx={{ width: '100%', borderRadius: 4, color: '#FFF', background: blue[600], '&:hover': { background: blue[600] } }} onClick={confirmSubmit} disabled={sendingConfirm}>확인</Button>
          </Box>
        }


      </Stack>
    </>
  );
});


export default CheckPhone;