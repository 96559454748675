import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # EmrAndSurveyItem
 *
 * EmrAndSurveyItem을 설명하세요.
 */
export const EmrAndSurveyItem = types
  .model("EmrAndSurveyItem")
  // --------------------------------------------------------------------------
  .props({
    code: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    english: types.optional(types.string, ''),
    imageUrl: types.optional(types.string, ''),
    korean: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model emrAndSurveyItem --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IEmrAndSurveyItem;
      (Object.keys(newProps) as TEmrAndSurveyItemKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TEmrAndSurveyItem = Instance<typeof EmrAndSurveyItem>;
type TEmrAndSurveyItemSnapshot = SnapshotOut<typeof EmrAndSurveyItem>

export interface IEmrAndSurveyItem extends TEmrAndSurveyItem { }
export type TEmrAndSurveyItemKeys = keyof TEmrAndSurveyItemSnapshot & string;
export interface IEmrAndSurveyItemSnapshot extends TEmrAndSurveyItemSnapshot { }
export const createEmrAndSurveyItem = () =>
  types.optional(EmrAndSurveyItem, {
  } as TEmrAndSurveyItem);
