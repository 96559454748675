
interface Message {
    type: string;
    payload?: any;
}

export const sendReactNativeMessage = ({ type, payload }: Message) => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
    }
};