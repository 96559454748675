import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Box, useTheme, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
//
import Header from './Header';
import Footer from './Footer';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function MobileLayout() {
  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Container sx={{ width: '100%', justifyContent: 'center', textAlign: 'center', p: 0 }}>
        <Box
          id="mobile-layout"
          component="main"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
          }}>
          <Stack
            sx={{
              py: 0,
              // pt: `${HEADER.H_MOBILE + SPACING}px`,
              pb: `${HEADER.H_MOBILE}px`,
              m: 'auto',
              maxWidth: theme.breakpoints.only('lg'),
              minHeight: '100vh',
              maxHeight: `calc(100vh - ${(HEADER.H_MOBILE)}px)`,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Outlet />
          </Stack>
          <Footer />
        </Box>
      </Container>
    </>
  );
}
