import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from "../extensions/with-root-store";
import {
  TokApi,
  TGetToksResult
} from "../../services";
import { ITok, Tok } from "../tok/Tok";

/**
 * # TokStore
 *
 * TokStore을 설명하세요.
 */
export const TokStore = types
  .model("TokStore")
  // --------------------------------------------------------------------------
  .props({
    toks: types.optional(types.array(Tok), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * toks을 교체
     *
     * @param `toks` 새로운 모델의 배열
     */
    setToks: (toks: ITok[]) => {
      self.toks.replace(toks);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Toks를 교체한다. 실패시 에러 로그를 남긴다.
     */
    getToks: async () => {
      try {
        const tokApi: TokApi = new TokApi(self.environment.api);
        const result: TGetToksResult = await tokApi.gets();
        if (result.kind === 'ok') {
          self.setToks(result.toks);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        console.log('🌈 ~ getToks: ~ e', e)
      }
    }
  }))

// --------------------------------------------------------------------------
type TTokStore = Instance<typeof TokStore>;
type TTokStoreSnapshot = SnapshotOut<typeof TokStore>

export interface ITokStore extends TTokStore { }
export type TTokStoreKeys = keyof TTokStoreSnapshot & string;
export interface ITokStoreSnapshot extends TTokStoreSnapshot { }
export const createTokStore = () =>
  types.optional(TokStore, {} as TTokStore);
