import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Model description here for TypeScript hints.
 */
export const AuthModel = types
  .model('Auth')
  .props({
    isAuthenticated: types.optional(types.boolean, false),
    isInitialized: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsAuthenticated: (isAuthenticated: boolean) => {
      self.isAuthenticated = isAuthenticated;
    },
    setIsInitialized: (isInitialized: boolean) => {
      self.isInitialized = isInitialized;
    },
    setAuthStatus: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as Auth;
      (Object.keys(updated) as Keys[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
  }));

type AuthType = Instance<typeof AuthModel>;
export interface Auth extends AuthType {}
type AuthSnapshotType = SnapshotOut<typeof AuthModel>;
export type Keys = keyof AuthSnapshotType;
export interface AuthSnapshot extends AuthSnapshotType {}
export const createAuthDefaultModel = () =>
  types.optional(AuthModel, {
    isAuthenticated: true,
    isInitialized: true,
  });
