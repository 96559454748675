import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { string } from 'prop-types';
import { UserApi } from 'src/services/user/user-api';
import { sendReactNativeMessage } from 'src/utils/sendReactNativeMessage';
import { withEnvironment } from '../../models/extensions/with-environment';
import { createUserDefaultModel, IUser, UserModel } from '../../models/user/user';
import {
  GetUserResult,
} from './../../services/user/user-result-types';
import { withRootStore } from './../extensions/with-root-store';
import { IUserSnapshot } from './../user/user';

export const UserStoreModel = types
  .model('UserStore')
  .props({
    user: createUserDefaultModel(),
    os: types.optional(types.string, ''),
    order_id: types.optional(types.string, ''),
    verify_info: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setUser: (user: IUserSnapshot) => {
      self.user = user as IUser;
    },
    setOS: (os: string) => {
      self.os = os;
    },
    setOrderId: (order_id: string) => {
      self.order_id = order_id;
    },
    setVerifyInfo: (jsonstr: string) => {
      self.verify_info = jsonstr;
    }
  }))
  .actions((self) => ({
    get: async () => {
      return self.user;
    },
    set: async (req: IUserSnapshot) => {
      self.setUser({
        userName: req.userName
      } as IUserSnapshot);
    },
    veriftInfo: (str: string) => {
      self.setVerifyInfo(str);
    },
    setUserName: (name: string) => {
      const user = self.user;
      self.setUser({
        ...user,
        userName: name
      });

      sendReactNativeMessage({
        type: 'updateUser',
        payload: {
          userName: name
        }
      });
    }

  }));
type UserStoreType = Instance<typeof UserStoreModel>;
export interface UserStore extends UserStoreType { }
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>;
export interface UserStoreSnapshot extends UserStoreSnapshotType { }

export interface IUserFilter {
  userId?: number | null;
  userToken?: string;
  userName?: string;
}

export type TUserFilter = IUserFilter;
