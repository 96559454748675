import { useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Paper, Link, Typography, Stack, Chip, Button } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import Carousel, { CarouselArrows } from 'src/components/carousel';
import Image from 'src/components/image';
import { blue, cyan, green } from '@mui/material/colors';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
// ----------------------------------------------------------------------

type CarouselItemProps = {
  discount: number;
  id: number;
  imageUrl: string;
  isDtc: boolean;
  linkUrl: string | null;
  name: string;
  price: number;
  remark: string;
};
type Props = {
  data: CarouselItemProps[];
};

export default function CarouselProduct({ data }: Props) {

  const carouselRef = useRef<Carousel | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'xl');

  const carouselSettings = {
    rtl: false,
    dots: false,
    infinite: false,
    initialSlide: data.length < 1 ? data.length : 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    centerPadding: useResponsive('up', 'md') ? '340px' : '60px',
    speed: 400,
    easing: 'ease-out',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          centerPadding: '40px',
          speed: 200,
        },
      },
    ],
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next + 1);
    },
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <CarouselArrows
          filled
          onNext={handleNext}
          onPrevious={handlePrev}
          leftButtonProps={{
            sx: {
              display: isDesktop ? 'block' : 'none'
            }
          }}
          rightButtonProps={{
            sx: {
              display: isDesktop ? 'block' : 'none'
            }
          }}>
          <Carousel ref={carouselRef} {...carouselSettings}>
            {data.map((item) => (
              <Box key={item.id} sx={{ px: 1 }}>
                <CarouselItem item={item} />
              </Box>
            ))}
          </Carousel>
        </CarouselArrows>
        <Box sx={{ fontSize: '.7rem', pt: 2, textAlign: 'center' }}>
          {currentSlide} <span style={{ color: alpha(theme.palette.grey[900], .5), fontSize: '.6rem' }}> / {data.length}</span>
        </Box>
      </Box >
    </>
  );
}

// ----------------------------------------------------------------------


function CarouselItem({ item }: { item: CarouselItemProps }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const { imageUrl, name, remark, linkUrl, price } = item;

  return (
    <Paper
      sx={{
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
        border: `1px solid #CCC`,
      }}
    >

      <Chip color={'error'} label={'New'} size={'small'} sx={{ position: 'absolute', left: { xs: '8%', md: '5%' }, top: { xs: '4%', md: '3.5%' }, zIndex: 2 }} />

      <Image alt={name} src={imageUrl} borderRadius={4} effect={'blur'} ratio={(isDesktop ? '1/1' : '1/1')} disabledEffect />

      {
        linkUrl !== '' && <Chip size={'small'} label={'직접 구매 가능'} sx={{ ...chipStyle }} />
      }
      {
        linkUrl === '' && <Chip size={'small'} label={'뱡원 구매 가능'} sx={{ ...chipStyle, background: cyan[400] }} />
      }

      <Stack sx={{
        ml: { xl: 3, lg: 4, xs: 3 },
        mt: { xl: 2, lg: 2, xs: 1 },
        pb: 4,
        pr: 2
      }}>
        <Typography variant={'h4'} sx={{ mb: 0 }}>{name}</Typography>
        <Typography variant={'body2'} sx={{ pb: 1, fontSize: '0.8rem', minHeight: '3rem' }}>{remark}</Typography>
        {
          price !== 0 &&
          <Stack direction={'row'} alignItems={'baseline'}>
            <Typography variant={'body1'} sx={{ fontSize: '1.6rem', mr: .5 }}>{price}</Typography>원
          </Stack>
        }
        {
          linkUrl !== null &&
          <Button variant={'text'}
            sx={{ background: '#FFF', color: alpha(theme.palette.text.primary, .6), alignSelf: 'flex-end' }}
            onClick={() => {
              window.open(linkUrl, '_blank');
            }}>구매하러 가기 <ArrowIcon sx={{ opacity: .7 }} /></Button>
        }
        {
          linkUrl === null && <Typography variant={'body2'} sx={{ alignSelf: 'center', mt: 5, mb: 1 }}>의뢰하신 병원에서 검사하실 수 있습니다</Typography>
        }

      </Stack>

    </Paper >
  );
}

const chipStyle = {
  background: blue[500], color: '#FFF',
  ml: { xl: 3, lg: 4, xs: 3 },
  mt: { xl: 3, lg: 3, xs: 2 },
};