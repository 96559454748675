import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import palette from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
    }),
    [themeDirection, themeMode]
  );

  /**
    Device 지원
    AOS Flat device, foldable phone(Z Fold, Z Flip)
    폰 및 패블릿을 기본으로 지원
    갤럭시 Z Fold4(2176 × 1812)이상의 비율은 이번 과업(1차개발)에서는 대상은 아님
        (Reference: Instagram과 같은 Layout비율로 개발 요청)

    2. 화면 가로 지원
    GenTok은 portrait(세로)만 지원함
    Landscape는 지원X

    3. 지원 OS
    Min SDK version: AOS 10이상
    Target SDK version: AOS 12
    Build SDK version: AOS 13

    4. App version(해당 버전으로 진행 부탁드리겠습니다.)
    1) AOS
    versionCode: 163
    versionName: 3.0.0
    2) iOS
    Build number: 70
    Version: 3.0.0
   */
  const theme = createTheme(
    { ...themeOptions },
    {
      breakpoints: {
        values: {
          lg: 1088 // 2176/2
        }
      },
    }
  );

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
