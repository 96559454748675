import { Outlet } from 'react-router-dom';
import useOffSetTop from '../../hooks/useOffSetTop';
import { HEADER } from '../../config-global';
import { Box, Container, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const theme = useTheme();

  return (

    <>
      <Container sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
        <Box
          component="main"
          sx={{
            maxWidth: theme.breakpoints.values.lg,
          }}>

          <Outlet />

        </Box>
      </Container>
    </>

  );
}
